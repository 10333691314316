import {
    formatError,
    showToast,
    login,
    sendOTP,
    runLogoutTimer,
    saveTokenInLocalStorage,
    updateTokenInLocalStorage,
    signUp,
    checkLogout,
    updateProfile,
    updateAgentProfile,
} from '../../services/AuthService';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const EMAIL_CONFIRMED_ACTION = '[email action] confirmed login';
export const EMAIL_FAILED_ACTION = '[email action] failed login';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const CHECK_STATUS_ACTION = '[Status action] status action';

export function signupAction(name, email, password, navigate) {
    return (dispatch) => {
        signUp(name, email, password)
            .then((response) => {
                const successMessage = showToast(response.data);
                dispatch(confirmedSignupAction(response.data));
                navigate('/login');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export function logout(navigate) {
    localStorage.removeItem('userDetails');
    navigate('/login');
    return {
        type: LOGOUT_ACTION,
    };
}

export function doLogout(navigate) {
    return (dispatch) => {
        checkLogout()
            .then((response) => {
                const successMessage = showToast(response.data);
                dispatch(logout(navigate));
            })
            .catch((error) => {
                dispatch(logout(navigate));
            });
    };

}

export function sendOtpAction(email, navigate) {
    return (dispatch) => {
        sendOTP(email)
            .then((response) => {
                const successMessage = showToast(response.data);
                dispatch(emailConfirmedAction(response.data));
            })
            .catch((error) => {
                // const errorMessage = formatError(error.response.data);
                const errorMessage = showToast(error.response.data);
                dispatch(emailFailedAction(errorMessage));
            });
    };
}

export function loginAction(email, otp, navigate) {
    return (dispatch) => {
        login(email, otp)
            .then((response) => {
                const successMessage = showToast(response.data);
                saveTokenInLocalStorage(response.data.result);
                runLogoutTimer(
                    dispatch,
                    72000 * 1000,
                    navigate,
                );
                dispatch(loginConfirmedAction(response.data.result));
                //history.push('/dashboard');                
                navigate('/dashboard');
            })
            .catch((error) => {
                const errorMessage = showToast(error.response.data);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function updateProfileAction(name, address, city, state, county, zip, navigate) {
    return (dispatch) => {
        updateProfile(name, address, city, state, county, zip)
            .then((response) => {
                const successMessage = showToast(response.data);
                updateTokenInLocalStorage(response.data.result);
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
                dispatch(loadingToggleAction(false));
            });
    };
}

export function updateAgentProfileAction(first_name, middle_name, last_name, office, direct_phone, cell_phone, office_phone, dre, nred, navigate) {
    return (dispatch) => {
        updateAgentProfile(first_name, middle_name, last_name, office, direct_phone, cell_phone, office_phone, dre, nred)
            .then((response) => {
                const successMessage = showToast(response.data);
                updateTokenInLocalStorage(response.data.result);
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
                dispatch(loadingToggleAction(false));
            });
    };
}

export function emailFailedAction(data) {
    return {
        type: EMAIL_FAILED_ACTION,
        payload: data,
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function emailConfirmedAction(data) {
    return {
        type: EMAIL_CONFIRMED_ACTION,
        payload: data,
        message: data.message,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

export function resetStageAction(navigate) {
    navigate('/register');
    return {
        type: CHECK_STATUS_ACTION,
    };
}