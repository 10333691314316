/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link, useLocation } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import plus from "../../../images/plus.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);



    //handleheartBlast.addEventListener('click', heartBlast);

  }, []);
  //sidebar icon Heart blast
  var handleheartBlast = document.querySelector('.heart');
  function heartBlast() {
    return handleheartBlast.classList.toggle("heart-blast");
  }

  // For scroll 
  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )

  //let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  /// Active menu
  let deshBoard = ["dashboard"],
    configuration = ["configuration"],
    downloadData = ["download-data"],
    propertyListing = ["property-listing"],
    userListing = ["users"]

  const MENU_ITEMS = [
    { label: 'Dashboard', link: '/dashboard', roles: ['superadmin', 'broker', 'agent'], icon: 'flaticon-025-dashboard' },
    { label: 'Configuration', link: '/configuration', roles: ['broker'], icon: 'flaticon-381-settings-4' },
    { label: 'Download Data', link: '/download-data', roles: ['broker'], icon: 'flaticon-381-download' },
    // { label: 'Property Listing', link: '/property-listing', roles: ['broker'] },
    { label: 'Trestle Data', link: '/trestle-data', roles: ['broker', 'agent'], icon: 'flaticon-022-copy' },
    { label: 'Spark Data', link: '/spark-data', roles: ['broker', 'agent'], icon: 'flaticon-022-copy' },
    { label: 'MLSGrid Data', link: '/mlsgrid-data', roles: ['broker', 'agent'], icon: 'flaticon-022-copy' },
    // { label: 'Users', link: '/users', roles: ['superadmin'] },
    { label: 'Brokers', link: '/brokers', roles: ['superadmin'], icon: 'flaticon-381-user-9' },
    { label: 'Agents', link: '/agents', roles: ['broker'], icon: 'flaticon-381-user-9' },
  ];

  const location = useLocation();

  let user = JSON.parse(localStorage.getItem('userDetails'))
  let userRole = user.authorities.name;

  const filteredMenuItems = MENU_ITEMS.filter(item => item.roles.includes(userRole));

  return (
    <div
      className={`deznav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? hideOnScroll > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">

          {filteredMenuItems.map((item, i) => (

            <li key={i} className={item.link === location.pathname ? 'mm-active' : ''}>
              <Link className="ai-icon" to={`${item.link}`} title={item.label}>
                <i className={item.icon}></i>
                <span className="nav-text">{item.label}</span>
              </Link>
            </li>
          ))}
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
