
const initialState = {
    brokerData: [],
    singleBrokerData: {
        _id: '',
        name: '',
        email: '',
        direct_phone: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zip: '',
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function BrokerReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_BROKERS_SUCCESS':
            return {
                ...state,
                showLoading: false,
                brokerData: action.payload,
                errorMessage: '',
                successMessage: '',
            };
        case 'FETCH_BROKERS_FAILURE':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        case 'BROKERS_LOADING_TOGGLE_ACTION':
            return {
                ...state,
                showLoading: action.payload,
            };
        case 'BROKER_SIGNUP_CONFIRMED_ACTION':
            return {
                ...state,
                showLoading: false,
                errorMessage: '',
                successMessage: action.payload.message,
            };
        case 'BROKER_SIGNUP_FAILED_ACTION':
            return {
                ...state,
                showLoading: false,
                errorMessage: action.payload,
                successMessage: '',
            };
        case 'FETCH_SINGLE_BROKER_SUCCESS':
            return {
                ...state,
                showLoading: false,
                singleBrokerData: action.payload
            };
        case 'FETCH_SINGLE_BROKER_FAILURE':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
}


