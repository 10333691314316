import React, { Fragment, useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
// import styles
import { Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { loadingToggleAction, updateProfileAction } from '../../../../store/actions/AuthActions';

import PageTitle from "../../../layouts/PageTitle";

const validateConfig = Yup.object().shape({
	name: Yup.string()
		.min(5, "Your name must consist of at least 5 characters ")
		.max(40, "Your name must consist of maximum 40 characters ")
		.required("Please Provide Name"),
	address: Yup.string()
		.min(10, "Your address must be at least 10 characters long")
		.max(100, "Your address must be at maximum 100 characters long")
		.required("Please Provide Address"),
	city: Yup.string()
		.min(3, "Your city must be at least 3 characters long")
		.max(50, "Your city must be at maximum 50 characters long")
		.required("Please Provide City"),
	state: Yup.string()
		.min(3, "Your state must be at least 3 characters long")
		.max(50, "Your state must be at maximum 50 characters long")
		.required("Please Provide State"),
	country: Yup.string()
		.min(3, "Your country must be at least 3 characters long")
		.max(50, "Your country must be at maximum 50 characters long")
		.required("Please Provide Country"),
	zip: Yup.string()
		.min(5, "Your zip code must be at least 5 characters long")
		.max(6, "Your zip code must be at maximum 6 characters long")
		.required("Please Provide Zip Code"),

});

const AppProfile = ({ showLoading }) => {

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const userData = JSON.parse(localStorage.getItem('userDetails'));
	const [values, setValues] = useState({
		name: userData.name || '',
		address: userData.address || '',
		city: userData.city || '',
		state: userData.state || '',
		country: userData.country || '',
		zip: userData.zip || '',
	});

	const handleSubmit = (values, { setSubmitting }) => {
		dispatch(loadingToggleAction(true));
		dispatch(updateProfileAction(values.name, values.address, values.city, values.state, values.country, values.zip, navigate));

		setSubmitting(false);
	};

	return (
		<Fragment>
			<PageTitle activeMenu="Profile" motherMenu="Home" />

			{showLoading && (
				<div className='loader-sec'>
					<div className="loader">Loading...</div>
				</div>
			)}

			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">Manage Profile</h4>
						</div>
						<div className="card-body">
							<div className="basic-form">
								<Formik
									initialValues={values}
									validationSchema={validateConfig}
									onSubmit={handleSubmit}
								>
									{({
										values,
										errors,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										resetForm
									}) => (
										<form onSubmit={handleSubmit}>
											<div
												className={`form-group mb-3 ${values.name
													? errors.name
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												{/* <label className="text-label">Name</label> */}
												<div className="input-group">
													<span className="input-group-text col-2">
														Name
													</span>
													<input
														type="text"
														className="form-control"
														id="val-name1"
														autoComplete="off"
														placeholder="Enter Name"
														name="name"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.name}
													/>
													<div
														id="val-name1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.name && errors.name}
													</div>

													<div
														id="val-name1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													/>
												</div>
											</div>
											<div
												className={`form-group mb-3 ${values.address
													? errors.address
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												{/* <label className="text-label">Address</label> */}
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														Address
													</span>

													<input
														type="text"
														className="form-control"
														id="val-address1"
														autoComplete="off"
														name="address"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.address}
														placeholder="Enter Address"
													/>													<div
														id="val-name1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.address && errors.address}
													</div>
												</div>
											</div>
											<div
												className={`form-group mb-3 ${values.city
													? errors.city
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												{/* <label className="text-label">City</label> */}
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														City
													</span>

													<input
														type="text"
														className="form-control"
														id="val-city1"
														autoComplete="off"
														name="city"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.city}
														placeholder="Enter City"
													/>													<div
														id="val-name1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.city && errors.city}
													</div>
												</div>
											</div>

											<div
												className={`form-group mb-3 ${values.state
													? errors.state
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												{/* <label className="text-label">State</label> */}
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														State
													</span>

													<input
														type="text"
														className="form-control"
														id="val-state1"
														autoComplete="off"
														name="state"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.state}
														placeholder="Enter State"
													/>													<div
														id="val-name1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.state && errors.state}
													</div>
												</div>
											</div>

											<div
												className={`form-group mb-3 ${values.country
													? errors.country
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												{/* <label className="text-label">Country</label> */}
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														Country
													</span>

													<input
														type="text"
														className="form-control"
														id="val-country1"
														autoComplete="off"
														name="country"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.country}
														placeholder="Enter Country"
													/>													<div
														id="val-name1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.country && errors.country}
													</div>
												</div>
											</div>

											<div
												className={`form-group mb-3 ${values.zip
													? errors.zip
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												{/* <label className="text-label">Zip</label> */}
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														Zip
													</span>

													<input
														type="text"
														className="form-control"
														id="val-zip1"
														autoComplete="off"
														name="zip"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.zip}
														placeholder="Enter Zip"
													/>													<div
														id="val-name1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.zip && errors.zip}
													</div>
												</div>
											</div>

											<button
												type="submit"
												className="btn me-2 btn-primary"
												disabled={isSubmitting}
											>
												Submit
											</button>
											<button
												type="button"
												className="btn btn-danger light"
												onClick={() => resetForm()}
											>
												Cancel
											</button>
										</form>
									)}
								</Formik>

								<ToastContainer />
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		errorMessage: state.auth.errorMessage,
		successMessage: state.auth.successMessage,
		showLoading: state.auth.showLoading,
	};
};
export default connect(mapStateToProps)(AppProfile);
