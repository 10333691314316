import axios from 'axios';
import { API_URL } from '../apiConfig';

export function authHeader() {
    let user = JSON.parse(localStorage.getItem('userDetails'))

    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token }
    } else {
        return {}
    }
}

export function getUsersData() {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return axios.get(
        `${API_URL}/user/get/brokers?page=1&limit=100`,
        requestOptions,
    );
}

export function fetchSingleUser(id) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return axios.get(
        `${API_URL}/user/get/user/${id}`,
        requestOptions,
    );
}

export function updateUser(params) {

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    let name = params.name;
    let role = params.role;
    let address = params.address;
    let area = params.area;
    let city = params.city;
    let country = params.country;
    let email = params.email;
    let phone = params.phone;
    let password = params.password;
    let state = params.state;
    let zip = params.zip;

    //axios call
    const postData = {
        name,
        role,
        email,
        phone,
        password,
        address,
        area,
        city,
        state,
        country,
        zip,
        returnSecureToken: true,
    };
    return axios.post(
        `${API_URL}/auth/signup`,
        postData,
        requestOptions
    );
}