import axios from 'axios';
import { API_URL } from '../apiConfig';

export function authHeader() {
    let user = JSON.parse(localStorage.getItem('userDetails'))

    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token }
    } else {
        return {}
    }
}

export function getAgentsData(page, limit) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return axios.get(
        `${API_URL}/user/get/agents?page=${page}&limit=${limit}`,
        requestOptions,
    );
}

export function fetchSingleAgent(id) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return axios.get(
        `${API_URL}/user/get/user/${id}`,
        requestOptions,
    );
}

export function createAgent(params) {

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    let first_name = params.first_name;
    let middle_name = params.middle_name;
    let last_name = params.last_name;
    let role = params.role;
    let parent = params.parent;
    let office = params.office;
    let cell_phone = params.cell_phone;
    let direct_phone = params.direct_phone;
    let office_phone = params.office_phone;
    let date_of_birth = params.date_of_birth;
    let email = params.email;
    let dre = params.dre;
    let nred = params.nred;

    //axios call
    const postData = {
        first_name,
        middle_name,
        last_name,
        role,
        parent,
        email,
        office,
        cell_phone,
        direct_phone,
        office_phone,
        date_of_birth,
        dre,
        nred,
    };
    return axios.post(
        `${API_URL}/auth/agent_signup`,
        postData,
        requestOptions
    );
}

export function updateAgent(params) {

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    let id = params._id;
    let first_name = params.first_name;
    let middle_name = params.middle_name;
    let last_name = params.last_name;
    let email = params.email;
    let direct_phone = params.direct_phone;
    let office_phone = params.office_phone;
    let cell_phone = params.cell_phone;
    let mls_list_mlsgrid = params.mls_list_mlsgrid;
    let mls_list_spark = params.mls_list_spark;
    let mls_list_trestle = params.mls_list_trestle;
    let office = params.office;
    let dre = params.dre;
    let nred = params.nred;
    let date_of_birth = params.date_of_birth;

    //axios call
    const postData = {
        first_name,
        middle_name,
        last_name,
        email,
        direct_phone,
        office_phone,
        cell_phone,
        mls_list_mlsgrid,
        mls_list_spark,
        mls_list_trestle,
        office,
        dre,
        nred,
        date_of_birth,
    };
    return axios.post(
        `${API_URL}/user/update/user/${id}`,
        postData,
        requestOptions
    );
}

export function deleteAgent(id) {

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return axios.delete(
        `${API_URL}/user/delete/user/${id}`,
        requestOptions
    );
}