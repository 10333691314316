import React, { useState, useEffect } from 'react';

import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/avatar/552721.png";
import { Dropdown } from "react-bootstrap";
import LogoutPage from './Logout';

const Header = ({ onNote }) => {

  let user = JSON.parse(localStorage.getItem('userDetails'))
  // console.log('user', user)
  const email = user.email;
  const name = capitalizeWords(user.name ? user.name : user.first_name);
  let role = user.authorities.name;
  let profileRoute = (role === 'agent') ? 'view-profile' : 'app-profile';

  const [userEmail, setUserEmail] = useState(email);
  const [userName, setUserName] = useState(name);

  useEffect(() => {
    setUserEmail(email);
    setUserName(name);
  }, [email]);

  function capitalizeWords(str) {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div className="me-5 agenda">
                {/* <Link to={"#"} className="btn btn-primary"><i className="fas fa-calendar"></i>Agenda (24)</Link> */}
              </div>
            </div>
            <ul className="navbar-nav header-right main-notification">

              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
                  <div className="header-info me-3">
                    <span className="fs-18 font-w500 text-end">{userName}</span>
                    <small className="text-end fs-14 font-w400">{userEmail}</small>
                  </div>
                  <img src={profile} width={20} alt="" />
                </Dropdown.Toggle>

                <Dropdown.Menu align="right" className="mt-3 dropdown-menu dropdown-menu-end">
                  <Link to={`/${profileRoute}`} className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary"
                      width={18} height={18} viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ms-2">Profile </span>
                  </Link>

                  <LogoutPage />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div >
    </div >
  );
};

export default Header;
