import { showToast } from '../../services/AuthService';
import { fetchProperty, fetchSingleProperty, fetchImportProperty, fetchChartProperty } from '../../services/MLSGridService';

export function fetchPropertyAction(page, limit, sort, filterValues) {
    return (dispatch) => {
        fetchProperty(page, limit, sort, filterValues)
            .then((response) => {
                dispatch(fetchPropertySuccess(response.data.result));
            })
            .catch((error) => {
                const errorMessage = showToast(error.response.data);
                dispatch(fetchPropertyFailure(error.response));
            });
    };
}

export function fetchMLSGridPropertyAction(page, limit, sort, filterValues) {
    return (dispatch) => {
        fetchProperty(page, limit, sort, filterValues)
            .then((response) => {
                dispatch(fetchMLSGridPropertySuccess(response.data.result));
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                const errorMessage = showToast(error.response.data);
                dispatch(fetchMLSGridPropertyFailure(error.response));
            });
    };
}

export const fetchPropertySuccess = options => {
    return {
        type: 'FETCH_PROPERTIES_SUCCESS',
        payload: options
    };
};

export const fetchPropertyFailure = error => {
    return {
        type: 'FETCH_PROPERTIES_FAILURE',
        payload: error
    };
};

export const fetchMLSGridPropertySuccess = options => {
    return {
        type: 'FETCH_MLSGRID_PROPERTIES_SUCCESS',
        payload: options
    };
};

export const fetchMLSGridPropertyFailure = error => {
    return {
        type: 'FETCH_MLSGRID_PROPERTIES_FAILURE',
        payload: error
    };
};

export function loadingToggleAction(status) {
    return {
        type: 'MLSGRID_LOADING_TOGGLE_ACTION',
        payload: status,
    };
}

export function fetchSinglePropertyAction(propertyType, id) {
    return (dispatch) => {
        fetchSingleProperty(propertyType, id)
            .then((response) => {
                dispatch(fetchSinglePropertySuccess(response.data.result));
            })
            .catch((error) => {
                dispatch(fetchSinglePropertyFailure(error.response));
            });
    };
}

export const fetchSinglePropertySuccess = options => {
    return {
        type: 'FETCH_SINGLE_PROPERTY_SUCCESS',
        payload: options
    };
};

export const fetchSinglePropertyFailure = error => {
    return {
        type: 'FETCH_SINGLE_PROPERTY_FAILURE',
        payload: error
    };
};

export function fetchMLSGridImportDataAction(page, limit, filterValues) {
    return (dispatch) => {
        fetchImportProperty(page, limit, filterValues)
            .then((response) => {
                dispatch(fetchMLSGridImportSuccess(response.data.result));
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                dispatch(fetchMLSGridImportFailure(error.response));
            });
    };
}

export const fetchMLSGridImportSuccess = options => {
    return {
        type: 'FETCH_MLSGRID_IMPORT_SUCCESS',
        payload: options
    };
};

export const fetchMLSGridImportFailure = error => {
    return {
        type: 'FETCH_MLSGRID_IMPORT_FAILURE',
        payload: error
    };
};

export function fetchMLSGridChartDataAction(filterValues) {
    return (dispatch) => {
        fetchChartProperty(filterValues)
            .then((response) => {
                dispatch(fetchMLSGridChartSuccess(response.data.result));
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                dispatch(fetchMLSGridChartFailure(error.response));
            });
    };
}

export const fetchMLSGridChartSuccess = options => {
    return {
        type: 'FETCH_MLSGRID_CHART_SUCCESS',
        payload: options
    };
};

export const fetchMLSGridChartFailure = error => {
    return {
        type: 'FETCH_MLSGRID_CHART_FAILURE',
        payload: error
    };
};
