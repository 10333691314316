import React, { useState, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";

import {
	Row,
	Col,
	Card,
	Table,
} from "react-bootstrap";

//Import
import PageHead from '../Griya/PageHead';

import {
	loadingToggleAction,
	fetchTrestleImportDataAction,
	fetchSparkImportDataAction,
	fetchMLSGridImportDataAction,
} from '../../../store/actions/PropertyActions';

import {
	fetchTrestleChartDataAction,
} from '../../../store/actions/TrestleActions'

import {
	fetchSparkChartDataAction,
} from '../../../store/actions/SparkActions'

import {
	fetchMLSGridChartDataAction,
} from '../../../store/actions/MLSGridActions'

import {
	getAgentsDataAction,
} from '../../../store/actions/AgentActions';

import {
	getBrokersDataAction,
} from '../../../store/actions/BrokerActions';

import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

const TrestleStatusCharts = loadable(() =>
	pMinDelay(import("../charts/TrestleStatusCharts"), 3000)
);
const SparkStatusCharts = loadable(() =>
	pMinDelay(import("../charts/SparkStatusCharts"), 3000)
);
const MLSGridStatusCharts = loadable(() =>
	pMinDelay(import("../charts/MLSGridStatusCharts"), 3000)
);

const Home = ({ showLoading, listAgentData, listBrokerData, importTrestleData, importSparkData, importMLSGridData, osOptions, statusOptions, chartTrestleData, chartSparkData, chartMLSGridData }) => {

	const dispatch = useDispatch();

	let user = JSON.parse(localStorage.getItem('userDetails'))
	let sortByValue = user.sortBy ? user.sortBy : 'ModificationTimestamp';
	let role = user.authorities.name;

	let trestleConfig = user.trestleConfig;
	let sparkConfig = user.sparkConfig;
	let mlsgridConfig = user.mlsgridConfig;

	if (osOptions.length > 0) {
		user.sparkOsOptions = osOptions;
		localStorage.setItem('userDetails', JSON.stringify(user));
	}

	if (statusOptions.length > 0) {
		user.sparkStatusOptions = statusOptions;
		localStorage.setItem('userDetails', JSON.stringify(user));
	}

	const [importTrestleProperties, setImportTrestleProperties] = useState([]);
	const [importSparkProperties, setImportSparkProperties] = useState([]);
	const [importMLSGridProperties, setImportMLSGridProperties] = useState([]);
	const [agentsData, setAgentsData] = useState([]);
	const [totalAgents, setTotalAgents] = useState(0);
	const [brokersData, setBrokersData] = useState([]);
	const [totalBrokers, setTotalBrokers] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(5);
	const [filterTrestleValues, setFilterTrestleValues] = useState({
		PropertyType: 'trestle',
	});
	const [filterSparkValues, setFilterSparkValues] = useState({
		PropertyType: 'spark',
	});
	const [filterMLSGridValues, setFilterMLSGridValues] = useState({
		PropertyType: 'mlsgrid',
	});

	const [trestleStatusArray, setTrestleStatusArray] = useState([]);
	const [trestleStatusCountArray, setTrestleStatusCountArray] = useState([]);

	const [sparkStatusArray, setSparkStatusArray] = useState([]);
	const [sparkStatusCountArray, setSparkStatusCountArray] = useState([]);

	const [mlsgridStatusArray, setMLSGridStatusArray] = useState([]);
	const [mlsgridStatusCountArray, setMLSGridStatusCountArray] = useState([]);

	const [trestleCityArray, setTrestleCityArray] = useState([]);
	const [trestleCityCountArray, setTrestleCityCountArray] = useState([]);

	const [sparkCityArray, setSparkCityArray] = useState([]);
	const [sparkCityCountArray, setSparkCityCountArray] = useState([]);

	const [mlsgridCityArray, setMLSGridCityArray] = useState([]);
	const [mlsgridCityCountArray, setMLSGridCityCountArray] = useState([]);

	const [trestleCountryArray, setTrestleCountryArray] = useState([]);
	const [trestleCountryCountArray, setTrestleCountryCountArray] = useState([]);

	const [sparkCountryArray, setSparkCountryArray] = useState([]);
	const [sparkCountryCountArray, setSparkCountryCountArray] = useState([]);

	const [mlsgridCountryArray, setMLSGridCountryArray] = useState([]);
	const [mlsgridCountryCountArray, setMLSGridCountryCountArray] = useState([]);

	const fetchDashboardDetails = () => {

		dispatch(loadingToggleAction(true))

		dispatch(fetchTrestleImportDataAction(page, limit, filterTrestleValues));
		setImportTrestleProperties(importTrestleData.docs)

		if (role != 'superadmin') {

			dispatch(fetchSparkImportDataAction(page, limit, filterSparkValues));
			setImportSparkProperties(importSparkData.docs)

			dispatch(fetchMLSGridImportDataAction(page, limit, filterMLSGridValues));
			setImportMLSGridProperties(importMLSGridData.docs)
		}

		if (role != 'superadmin') {
			dispatch(fetchTrestleChartDataAction(filterTrestleValues));
			dispatch(fetchSparkChartDataAction(filterSparkValues));
			dispatch(fetchMLSGridChartDataAction(filterMLSGridValues));
		}

		if (role === 'superadmin') {
			dispatch(getBrokersDataAction(1, 5));
			setBrokersData(listBrokerData.docs)
			setTotalBrokers(listBrokerData.totalDocs)
		}
		if (role != 'agent') {
			dispatch(getAgentsDataAction(1, 5));
			setAgentsData(listAgentData.docs)
			setTotalAgents(listAgentData.totalDocs)
		}
	}

	useEffect(() => {
		if (role != 'agent') {
			setAgentsData(listAgentData.docs)
			setTotalAgents(listAgentData.totalDocs)
		}
	}, [listAgentData]);

	useEffect(() => {
		if (role === 'superadmin') {
			setBrokersData(listBrokerData.docs)
			setTotalBrokers(listBrokerData.totalDocs)
		}
	}, [listBrokerData]);

	useEffect(() => {
		setImportTrestleProperties(importTrestleData.docs)
	}, [importTrestleData]);

	useEffect(() => {
		if (role != 'superadmin') {
			setImportSparkProperties(importSparkData.docs)
		}
	}, [importSparkData]);

	useEffect(() => {
		if (role != 'superadmin') {
			setImportMLSGridProperties(importMLSGridData.docs)
		}
	}, [importMLSGridData]);

	useEffect(() => {
		if (role != 'superadmin') {
			if (chartTrestleData && chartTrestleData.groupByStatus && chartTrestleData.groupByStatus.length > 0) {
				const trestle_status_data = chartTrestleData.groupByStatus;

				// const sortedTrestleStatusData = [...trestle_status_data].sort((a, b) => a.status.localeCompare(b.status)).slice(0, 10);
				const sortedTrestleStatusData = [...trestle_status_data].sort((a, b) => b.count - a.count).slice(0, 10);
				setTrestleStatusArray(sortedTrestleStatusData.map(item => item.status))
				setTrestleStatusCountArray(sortedTrestleStatusData.map(item => item.count))

			}

			if (chartTrestleData && chartTrestleData.groupByCity && chartTrestleData.groupByCity.length > 0) {
				const trestle_city_data = chartTrestleData.groupByCity;

				const sortedTrestleCityData = [...trestle_city_data].sort((a, b) => b.count - a.count).slice(0, 10);
				setTrestleCityArray(sortedTrestleCityData.map(item => item.status))
				setTrestleCityCountArray(sortedTrestleCityData.map(item => item.count))
			}

			if (chartTrestleData && chartTrestleData.groupByCountry && chartTrestleData.groupByCountry.length > 0) {
				const trestle_country_data = chartTrestleData.groupByCountry;

				const sortedTrestleCountryData = [...trestle_country_data].sort((a, b) => b.count - a.count).slice(0, 10);
				setTrestleCountryArray(sortedTrestleCountryData.map(item => item.status))
				setTrestleCountryCountArray(sortedTrestleCountryData.map(item => item.count))
			}
		}

	}, [chartTrestleData]);

	useEffect(() => {

		if (role != 'superadmin') {
			if (chartSparkData && chartSparkData.groupByStatus) {
				const spark_status_data = chartSparkData.groupByStatus;

				// const sortedSparkStatusData = [...spark_status_data].sort((a, b) => a.status.localeCompare(b.status)).slice(0, 10);
				const sortedSparkStatusData = [...spark_status_data].sort((a, b) => b.count - a.count).slice(0, 10);
				setSparkStatusArray(sortedSparkStatusData.map(item => item.status))
				setSparkStatusCountArray(sortedSparkStatusData.map(item => item.count))
			}

			if (chartSparkData && chartSparkData.groupByCity) {
				const spark_city_data = chartSparkData.groupByCity;

				const sortedSparkCityData = [...spark_city_data].sort((a, b) => b.count - a.count).slice(0, 10);
				setSparkCityArray(sortedSparkCityData.map(item => item.status))
				setSparkCityCountArray(sortedSparkCityData.map(item => item.count))
			}

			if (chartSparkData && chartSparkData.groupByCountry) {
				const spark_country_data = chartSparkData.groupByCountry;

				const sortedSparkCountryData = [...spark_country_data].sort((a, b) => b.count - a.count).slice(0, 10);
				setSparkCountryArray(sortedSparkCountryData.map(item => item.status))
				setSparkCountryCountArray(sortedSparkCountryData.map(item => item.count))
			}
		}

	}, [chartSparkData]);

	useEffect(() => {

		if (role != 'superadmin') {
			if (chartMLSGridData && chartMLSGridData.groupByStatus) {
				const mlsgrid_status_data = chartMLSGridData.groupByStatus;

				// const sortedMLSGridStatusData = [...mlsgrid_status_data].sort((a, b) => a.status.localeCompare(b.status)).slice(0, 10);
				const sortedMLSGridStatusData = [...mlsgrid_status_data].sort((a, b) => b.count - a.count).slice(0, 10);
				setMLSGridStatusArray(sortedMLSGridStatusData.map(item => item.status))
				setMLSGridStatusCountArray(sortedMLSGridStatusData.map(item => item.count))
			}

			if (chartMLSGridData && chartMLSGridData.groupByCity) {
				const mlsgrid_city_data = chartMLSGridData.groupByCity;

				const sortedMLSGridCityData = [...mlsgrid_city_data].sort((a, b) => b.count - a.count).slice(0, 10);
				setMLSGridCityArray(sortedMLSGridCityData.map(item => item.status))
				setMLSGridCityCountArray(sortedMLSGridCityData.map(item => item.count))
			}

			if (chartMLSGridData && chartMLSGridData.groupByCountry) {
				const mlsgrid_country_data = chartMLSGridData.groupByCountry;

				const sortedMLSGridCountryData = [...mlsgrid_country_data].sort((a, b) => b.count - a.count).slice(0, 10);
				setMLSGridCountryArray(sortedMLSGridCountryData.map(item => item.status))
				setMLSGridCountryCountArray(sortedMLSGridCountryData.map(item => item.count))
			}
		}

	}, [chartMLSGridData]);

	useEffect(() => {
		fetchDashboardDetails();
	}, []);

	return (
		<>
			<PageHead activePage="Dashboard" pageName="Dashboard" />

			{showLoading && (
				<div className='loader-sec'>
					<div className="loader">Loading...</div>
				</div>
			)}

			{role != 'superadmin' && (trestleConfig || sparkConfig || mlsgridConfig) && <Tab.Container defaultActiveKey={trestleConfig ? 'Trestle' : (sparkConfig ? 'Spark' : (mlsgridConfig ? 'MLSGrid' : ''))}>
				<div className="card">
					<div className="card-header border-0  flex-wrap">
						<h4 className="fs-20">Group By MLS Status</h4>
						<div className="d-flex">
							<div className="card-action coin-tabs mt-3 mt-sm-0">
								<Nav as="ul" className="nav nav-tabs" role="tablist">
									{trestleConfig && (
										<Nav.Item as="li" className="nav-item">
											<Nav.Link className="nav-link" eventKey="Trestle" role="tab">
												Trestle
											</Nav.Link>
										</Nav.Item>
									)}
									{sparkConfig && (
										<Nav.Item className="nav-item">
											<Nav.Link className="nav-link" eventKey="Spark" role="tab" >
												Spark
											</Nav.Link>
										</Nav.Item>
									)}
									{mlsgridConfig && (
										<Nav.Item className="nav-item">
											<Nav.Link className="nav-link" eventKey="MLSGrid" role="tab" >
												MLSGrid
											</Nav.Link>
										</Nav.Item>
									)}
								</Nav>
							</div>
						</div>
					</div>
					<div className="card-body py-0 charts-min-height">
						<Tab.Content >
							<Tab.Pane eventKey="Trestle">
								<div id="chartTimeline1" className="timeline-chart">
									<TrestleStatusCharts trestleStatus={trestleStatusArray} trestleCount={trestleStatusCountArray} />
								</div>
							</Tab.Pane>
							<Tab.Pane eventKey="Spark">
								<div id="chartTimeline2" className="chart-timeline">
									<SparkStatusCharts sparkStatus={sparkStatusArray} sparkCount={sparkStatusCountArray} />
								</div>
							</Tab.Pane>
							<Tab.Pane eventKey="MLSGrid">
								<div id="chartTimeline3" className="chart-timeline">
									<MLSGridStatusCharts mlsgridStatus={mlsgridStatusArray} mlsgridCount={mlsgridStatusCountArray} />
								</div>
							</Tab.Pane>
						</Tab.Content>
					</div>
				</div>
			</Tab.Container>
			}

			{role != 'superadmin' && (trestleConfig || sparkConfig || mlsgridConfig) && <Tab.Container defaultActiveKey={trestleConfig ? 'Trestle' : (sparkConfig ? 'Spark' : (mlsgridConfig ? 'MLSGrid' : ''))}>
				<div className="card">
					<div className="card-header border-0  flex-wrap">
						<h4 className="fs-20">Group By City</h4>
						<div className="d-flex">
							<div className="card-action coin-tabs mt-3 mt-sm-0">
								<Nav as="ul" className="nav nav-tabs" role="tablist">
									{trestleConfig && (
										<Nav.Item as="li" className="nav-item">
											<Nav.Link className="nav-link" eventKey="Trestle" role="tab">
												Trestle
											</Nav.Link>
										</Nav.Item>
									)}
									{sparkConfig && (
										<Nav.Item className="nav-item">
											<Nav.Link className="nav-link" eventKey="Spark" role="tab" >
												Spark
											</Nav.Link>
										</Nav.Item>
									)}
									{mlsgridConfig && (
										<Nav.Item className="nav-item">
											<Nav.Link className="nav-link" eventKey="MLSGrid" role="tab" >
												MLSGrid
											</Nav.Link>
										</Nav.Item>
									)}
								</Nav>
							</div>
						</div>
					</div>
					<div className="card-body py-0 charts-min-height">
						<Tab.Content >
							<Tab.Pane eventKey="Trestle">
								<div id="chartTimeline1" className="timeline-chart">
									<TrestleStatusCharts trestleStatus={trestleCityArray} trestleCount={trestleCityCountArray} />
								</div>
							</Tab.Pane>
							<Tab.Pane eventKey="Spark">
								<div id="chartTimeline2" className="chart-timeline">
									<SparkStatusCharts sparkStatus={sparkCityArray} sparkCount={sparkCityCountArray} />
								</div>
							</Tab.Pane>
							<Tab.Pane eventKey="MLSGrid">
								<div id="chartTimeline3" className="chart-timeline">
									<MLSGridStatusCharts mlsgridStatus={mlsgridCityArray} mlsgridCount={mlsgridCityCountArray} />
								</div>
							</Tab.Pane>
						</Tab.Content>
					</div>
				</div>
			</Tab.Container>
			}

			{role != 'superadmin' && (trestleConfig || sparkConfig || mlsgridConfig) && <Tab.Container defaultActiveKey={trestleConfig ? 'Trestle' : (sparkConfig ? 'Spark' : (mlsgridConfig ? 'MLSGrid' : ''))}>
				<div className="card">
					<div className="card-header border-0  flex-wrap">
						<h4 className="fs-20">Group By Country</h4>
						<div className="d-flex">
							<div className="card-action coin-tabs mt-3 mt-sm-0">
								<Nav as="ul" className="nav nav-tabs" role="tablist">
									{trestleConfig && (
										<Nav.Item as="li" className="nav-item">
											<Nav.Link className="nav-link" eventKey="Trestle" role="tab">
												Trestle
											</Nav.Link>
										</Nav.Item>
									)}
									{sparkConfig && (
										<Nav.Item className="nav-item">
											<Nav.Link className="nav-link" eventKey="Spark" role="tab" >
												Spark
											</Nav.Link>
										</Nav.Item>
									)}
									{mlsgridConfig && (
										<Nav.Item className="nav-item">
											<Nav.Link className="nav-link" eventKey="MLSGrid" role="tab" >
												MLSGrid
											</Nav.Link>
										</Nav.Item>
									)}
								</Nav>
							</div>
						</div>
					</div>
					<div className="card-body py-0 charts-min-height">
						<Tab.Content >

							<Tab.Pane eventKey="Trestle">
								<div id="chartTimeline1" className="timeline-chart">
									<TrestleStatusCharts trestleStatus={trestleCountryArray} trestleCount={trestleCountryCountArray} />
								</div>
							</Tab.Pane>

							<Tab.Pane eventKey="Spark">
								<div id="chartTimeline2" className="chart-timeline">
									<SparkStatusCharts sparkStatus={sparkCountryArray} sparkCount={sparkCountryCountArray} />
								</div>
							</Tab.Pane>

							<Tab.Pane eventKey="MLSGrid">
								<div id="chartTimeline3" className="chart-timeline">
									<MLSGridStatusCharts mlsgridStatus={mlsgridCountryArray} mlsgridCount={mlsgridCountryCountArray} />
								</div>
							</Tab.Pane>

						</Tab.Content>
					</div>
				</div>
			</Tab.Container>
			}

			{role === 'broker' && (trestleConfig || sparkConfig || mlsgridConfig) && (
				<div className="col-xl-12">
					<div className="card">
						<Card.Header>
							<Card.Title>Import Data History</Card.Title>
						</Card.Header>
						<div className="card-body">
							<div className="profile-tab">
								<div className="custom-tab-1">
									<Tab.Container defaultActiveKey={trestleConfig ? 'Trestle' : (sparkConfig ? 'Spark' : (mlsgridConfig ? 'MLSGrid' : ''))}>
										<Nav as='ul' className="nav nav-tabs">
											{trestleConfig && (
												<Nav.Item as='li' className="nav-item">
													<Nav.Link to="#trestle-import" eventKey='Trestle'>Trestle</Nav.Link>
												</Nav.Item>
											)}
											{sparkConfig && (
												<Nav.Item as='li' className="nav-item">
													<Nav.Link to="#spark-import" eventKey='Spark'>Spark</Nav.Link>
												</Nav.Item>
											)}
											{mlsgridConfig && (
												<Nav.Item as='li' className="nav-item">
													<Nav.Link to="#mlsgrid-import" eventKey='MLSGrid'>MLSGrid</Nav.Link>
												</Nav.Item>
											)}
										</Nav>
										<Tab.Content>
											<Tab.Pane id="trestle-import" eventKey='Trestle'>
												<div className="my-post-content pt-3">
													{<TrestleImportSection importData={importTrestleProperties} />}
												</div>
											</Tab.Pane>
											<Tab.Pane id="spark-import" eventKey='Spark'>
												<div className="my-post-content pt-3">
													{<SparkImportSection importData={importSparkProperties} />}
												</div>
											</Tab.Pane>
											<Tab.Pane id="mlsgrid-import" eventKey='MLSGrid'>
												<div className="my-post-content pt-3">
													{<MLSGridImportSection importData={importMLSGridProperties} />}
												</div>
											</Tab.Pane>
										</Tab.Content>
									</Tab.Container>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{role === 'superadmin' && <BrokerSection brokerData={brokersData} totalBrokers={totalBrokers} />}

			{role === 'superadmin' && <AgentSection role={role} agentData={agentsData} totalAgents={totalAgents} />}

			{role === 'broker' && <AgentSection role={role} agentData={agentsData} totalAgents={totalAgents} />}

		</>
	)
}

const BrokerSection = ({ brokerData, totalBrokers }) => {
	return (
		<Row>
			<Col lg={12}>
				<Card>
					<Card.Header>
						<Card.Title>Brokers</Card.Title>
						<Link to="/brokers">
							<span className="float-right badge badge-primary badge-pill" style={{ fontSize: '1em' }}>
								Total: {totalBrokers}
							</span>
						</Link>
					</Card.Header>
					<Card.Body>
						<Table responsive>
							<thead>
								<tr>
									<th>
										<strong>Name</strong>
									</th>
									<th>
										<strong>Email</strong>
									</th>
									<th>
										<strong>Phone</strong>
									</th>
								</tr>
							</thead>
							<tbody>
								{brokerData && brokerData.length > 0 ? (
									brokerData.map((broker, index) => (
										<tr key={index}>
											<td>
												<div className="d-flex align-items-center">
													{" "}
													<span className="w-space-no">{broker.name}</span>
												</div>
											</td>
											<td>{broker.email}</td>
											<td>{broker.phone}</td>
										</tr>
									))
								) : (
									<tr>
										<td colSpan="3" className="text-center">No record found.</td>
									</tr>
								)}
							</tbody>
						</Table>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	)
};

const AgentSection = ({ role, agentData, totalAgents }) => {
	return (
		<Row>
			<Col lg={12}>
				<Card>
					<Card.Header>
						<Card.Title>Agents</Card.Title>
						{role && role !== 'superadmin' ? (
							<Link to="/agents">
								<span className="float-right badge badge-primary badge-pill" style={{ fontSize: '1em' }}>
									Total: {totalAgents}
								</span>
							</Link>
						) : (
							<span className="float-right badge badge-primary badge-pill" style={{ fontSize: '1em' }}>
								Total: {totalAgents}
							</span>
						)}
					</Card.Header>
					<Card.Body>
						<Table responsive>
							<thead>
								<tr>
									<th>
										<strong>First Name</strong>
									</th>
									<th>
										<strong>Last Name</strong>
									</th>
									<th>
										<strong>Email</strong>
									</th>
									<th>
										<strong>Phone</strong>
									</th>
									{role && role === 'superadmin' && (
										<>
											<th>
												<strong>Broker Name</strong>
											</th>
											<th>
												<strong>Broker Email</strong>
											</th>
										</>
									)}
								</tr>
							</thead>
							<tbody>
								{agentData && agentData.length > 0 ? (
									agentData.map((agent, index) => (
										<tr key={index}>
											<td>
												<div className="d-flex align-items-center">
													<span className="w-space-no">{agent.first_name}</span>
												</div>
											</td>
											<td>
												<div className="d-flex align-items-center">
													<span className="w-space-no">{agent.last_name}</span>
												</div>
											</td>
											<td>{agent.email}</td>
											<td>{agent.direct_phone}</td>
											{role === 'superadmin' && agent.parent && (
												<>
													<td>{agent.parent.name}</td>
													<td>{agent.parent.email}</td>
												</>
											)}

										</tr>
									))
								) : (
									<tr>
										<td colSpan={role === 'superadmin' ? 5 : 3} className="text-center">No record found.</td>
									</tr>
								)}
							</tbody>
						</Table>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	)
};

const TrestleImportSection = (importData) => {

	const capitalize = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	const statusClassName = (status) => {
		switch (status) {
			case 'processing':
				return 'text-warning';
			case 'completed':
				return 'text-success';
			default:
				return '';
		}
	}

	return (
		<Table responsive>
			<thead>
				<tr>
					<th>
						<strong>Collection Name</strong>
					</th>
					<th>
						<strong>Count</strong>
					</th>
					<th>
						<strong>Start Time</strong>
					</th>
					<th>
						<strong>End Time</strong>
					</th>
					<th>
						<strong>Time (Mins)</strong>
					</th>
					<th>
						<strong>Status</strong>
					</th>
				</tr>
			</thead>
			<tbody>
				{importData && importData.importData && importData.importData.length > 0 ? (
					importData.importData.map((importTrestle, index) => (
						<tr key={index}>
							<td>
								<div className="d-flex align-items-center">
									{" "}
									<span className="w-space-no">{importTrestle.collectionName.join(', ')}</span>
								</div>
							</td>
							<td>{importTrestle.dataCount}</td>
							<td>{new Date(importTrestle.createdAt).toLocaleString()}</td>
							<td>{new Date(importTrestle.updatedAt).toLocaleString()}</td>
							<td>{importTrestle.timeDifferenceInMins}</td>
							<td className={statusClassName(importTrestle.status)}>{capitalize(importTrestle.status)}</td>
						</tr>
					))
				) : (
					<tr>
						<td colSpan="6" className="text-center">No record found.</td>
					</tr>
				)}
			</tbody>
		</Table>
	)
};

const SparkImportSection = (importData) => {

	const capitalize = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	const statusClassName = (status) => {
		switch (status) {
			case 'processing':
				return 'text-warning';
			case 'completed':
				return 'text-success';
			default:
				return '';
		}
	}

	return (
		<Table responsive>
			<thead>
				<tr>
					<th>
						<strong>Collection Name</strong>
					</th>
					<th>
						<strong>Count</strong>
					</th>
					<th>
						<strong>Start Time</strong>
					</th>
					<th>
						<strong>End Time</strong>
					</th>
					<th>
						<strong>Time (Mins)</strong>
					</th>
					<th>
						<strong>Status</strong>
					</th>
				</tr>
			</thead>
			<tbody>
				{importData && importData.importData && importData.importData.length > 0 ? (
					importData.importData.map((importSpark, index) => (
						<tr key={index}>
							<td>
								<div className="d-flex align-items-center">
									{" "}
									<span className="w-space-no">{importSpark.collectionName.join(', ')}</span>
								</div>
							</td>
							<td>{importSpark.dataCount}</td>
							<td>{new Date(importSpark.createdAt).toLocaleString()}</td>
							<td>{new Date(importSpark.updatedAt).toLocaleString()}</td>
							<td>{importSpark.timeDifferenceInMins}</td>
							<td className={statusClassName(importSpark.status)}>{capitalize(importSpark.status)}</td>
						</tr>
					))
				) : (
					<tr>
						<td colSpan="6" className="text-center">No record found.</td>
					</tr>
				)}
			</tbody>
		</Table>
	)
};

const MLSGridImportSection = (importData) => {

	const capitalize = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	const statusClassName = (status) => {
		switch (status) {
			case 'processing':
				return 'text-warning';
			case 'completed':
				return 'text-success';
			default:
				return '';
		}
	}

	return (
		<Table responsive>
			<thead>
				<tr>
					<th>
						<strong>Collection Name</strong>
					</th>
					<th>
						<strong>Count</strong>
					</th>
					<th>
						<strong>Start Time</strong>
					</th>
					<th>
						<strong>End Time</strong>
					</th>
					<th>
						<strong>Time (Mins)</strong>
					</th>
					<th>
						<strong>Status</strong>
					</th>
				</tr>
			</thead>
			<tbody>
				{importData && importData.importData && importData.importData.length > 0 ? (
					importData.importData.map((importSpark, index) => (
						<tr key={index}>
							<td>
								<div className="d-flex align-items-center">
									{" "}
									<span className="w-space-no">{importSpark.collectionName.join(', ')}</span>
								</div>
							</td>
							<td>{importSpark.dataCount}</td>
							<td>{new Date(importSpark.createdAt).toLocaleString()}</td>
							<td>{new Date(importSpark.updatedAt).toLocaleString()}</td>
							<td>{importSpark.timeDifferenceInMins}</td>
							<td className={statusClassName(importSpark.status)}>{capitalize(importSpark.status)}</td>
						</tr>
					))
				) : (
					<tr>
						<td colSpan="6" className="text-center">No record found.</td>
					</tr>
				)}
			</tbody>
		</Table>
	)
};

const mapStateToProps = (state) => {
	// console.log('state', state)
	return {
		errorMessage: state.config.errorMessage,
		successMessage: state.config.successMessage,
		showLoading: state.property.showLoading,
		statusOptions: state.groupby.statusOptions,
		listAgentData: state.agents.agentData,
		listBrokerData: state.brokers.brokerData,
		importTrestleData: state.property.importTrestleData,
		importSparkData: state.property.importSparkData,
		importMLSGridData: state.property.importMLSGridData,
		chartTrestleData: state.trestle.chartTrestleData,
		chartSparkData: state.spark.chartSparkData,
		chartMLSGridData: state.mlsgrid.chartMLSGridData,
		osOptions: state.groupby.osOptions,
		statusOptions: state.groupby.statusOptions,
	};
};
export default connect(mapStateToProps)(Home);