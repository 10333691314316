import React, { Fragment, useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";

// import styles
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadingToggleAction, updateBrokerAction, fetchSingleBrokerAction } from '../../../store/actions/BrokerActions';
import PageTitle from "../../layouts/PageTitle";
import './brokerlist.css';


const validateConfig = Yup.object().shape({
	name: Yup.string()
		.min(5, "Your name must consist of at least 5 characters")
		.max(40, "Your name must consist of maximum 40 characters")
		.required("Please Provide Name"),
	email: Yup.string()
		.min(5, "Your Email must consist of at least 5 characters")
		.max(80, "Your Email must consist of maximum 80 characters")
		.email("Please enter valid email address")
		.required("Please Provide Email"),
	direct_phone: Yup.string()
		.matches(/^[0-9]{10}$/, 'Phone number is not valid')
		.min(10, "Your phone number must consist of at least 10 characters")
		.max(10, "Your Phone number must consist of maximum 10 characters")
		.required("Please Provide Phone Number"),
	address: Yup.string()
		.min(10, "Your address must be at least 10 characters long")
		.max(100, "Your address must be at maximum 100 characters long")
		.required("Please Provide Address"),
	city: Yup.string()
		.min(3, "Your city must be at least 3 characters long")
		.max(50, "Your city must be at maximum 50 characters long")
		.required("Please Provide City"),
	state: Yup.string()
		.min(3, "Your state must be at least 3 characters long")
		.max(50, "Your state must be at maximum 50 characters long")
		.required("Please Provide State"),
	country: Yup.string()
		.min(3, "Your country must be at least 3 characters long")
		.max(50, "Your country must be at maximum 50 characters long")
		.required("Please Provide Country"),
	zip: Yup.string()
		.min(5, "Your zip code must be at least 5 characters long")
		.max(6, "Your zip code must be at maximum 6 characters long")
		.required("Please Provide Zip Code"),
});

const UpdateBroker = ({ singleBrokerData, showLoading }) => {

	let user = JSON.parse(localStorage.getItem('userDetails'))
	const { id } = useParams();

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [items, setItems] = useState(singleBrokerData);

	useEffect(() => {
		setItems(singleBrokerData);
	}, [singleBrokerData]);

	useEffect(() => {
		dispatch(loadingToggleAction(true));
		dispatch(fetchSingleBrokerAction(id));
		setItems(singleBrokerData);
	}, [id, dispatch]);

	const handleBackButtonClick = () => {
		setItems({});
		navigate('/brokers');
	};

	const handleSubmit = (values, { setSubmitting }) => {
		dispatch(loadingToggleAction(true));
		dispatch(updateBrokerAction(values, navigate));
	};

	return (
		<Fragment>
			<PageTitle activeMenu="Broker" motherMenu="Home" />

			{showLoading && (
				<div className='loader-sec'>
					<div className="loader">Loading...</div>
				</div>
			)}

			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">Update Broker</h4>
							<div className="custom-dropdown mb-0 dropdown">
								<Link to="/brokers">
									<button
										type="button"
										className="me-2 btn btn-primary btn-sm btn-rounded"
										onClick={handleBackButtonClick}
									>
										<span className="btn-icon-start text-info">
											<i className="fa fa-arrow-left color-info"></i>
										</span>Back
									</button>
								</Link>
							</div>
						</div>
						<div className="card-body">
							<div className="basic-form">
								<Formik
									initialValues={items}
									validationSchema={validateConfig}
									onSubmit={handleSubmit}
									enableReinitialize
								>
									{({
										errors,
										values,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										resetForm
									}) => (
										<form onSubmit={handleSubmit}>
											<div
												className={`form-group mb-3 ${values.name
													? errors.name
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												<div className="input-group">
													<span className="input-group-text col-2">
														Name
													</span>
													<input
														type="text"
														className="form-control"
														id="val-name1"
														autoComplete="off"
														placeholder="Enter Name"
														name="name"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.name}
													/>
													<input
														type="hidden"
														className="form-control"
														id="val-id1"
														autoComplete="off"
														name="_id"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values._id}
													/>
													<div
														id="val-name1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.name && errors.name}
													</div>

													<div
														id="val-name1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													/>
												</div>
											</div>

											<div
												className={`form-group mb-3 ${values.email
													? errors.email
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												<div className="input-group">
													<span className="input-group-text col-2">
														Email
													</span>
													<input
														type="email"
														className="form-control"
														id="val-name3"
														autoComplete="off"
														placeholder="Enter Email"
														name="email"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.email}
													/>
													<div
														id="val-name3-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.email && errors.email}
													</div>

													<div
														id="val-name3-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													/>
												</div>
											</div>
											<div
												className={`form-group mb-3 ${values.direct_phone
													? errors.direct_phone
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												<div className="input-group">
													<span className="input-group-text col-2">
														Phone Number
													</span>
													<input
														type="tel"
														className="form-control"
														id="val-direct_phone1"
														minLength={10}
														maxLength={10}
														autoComplete="off"
														placeholder="Enter Phone Number"
														name="direct_phone"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.direct_phone}
													/>
													<div
														id="val-direct_phone1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.direct_phone && errors.direct_phone}
													</div>

													<div
														id="val-direct_phone1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													/>
												</div>
											</div>
											<div
												className={`form-group mb-3 ${values.address
													? errors.address
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														Address
													</span>

													<input
														type="text"
														className="form-control"
														id="val-address1"
														autoComplete="off"
														name="address"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.address}
														placeholder="Enter Address"
													/>
													<div
														id="val-address1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.address && errors.address}
													</div>
												</div>
											</div>
											<div
												className={`form-group mb-3 ${values.city
													? errors.city
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												{/* <label className="text-label">City</label> */}
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														City
													</span>

													<input
														type="text"
														className="form-control"
														id="val-city1"
														autoComplete="off"
														name="city"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.city}
														placeholder="Enter City"
													/>
													<div
														id="val-city1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.city && errors.city}
													</div>
												</div>
											</div>

											<div
												className={`form-group mb-3 ${values.state
													? errors.state
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														State
													</span>

													<input
														type="text"
														className="form-control"
														id="val-state1"
														autoComplete="off"
														name="state"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.state}
														placeholder="Enter State"
													/>
													<div
														id="val-state1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.state && errors.state}
													</div>
												</div>
											</div>

											<div
												className={`form-group mb-3 ${values.country
													? errors.country
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														Country
													</span>

													<input
														type="text"
														className="form-control"
														id="val-country1"
														autoComplete="off"
														name="country"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.country}
														placeholder="Enter Country"
													/>
													<div
														id="val-country1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.country && errors.country}
													</div>
												</div>
											</div>

											<div
												className={`form-group mb-3 ${values.zip
													? errors.zip
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														Zip
													</span>

													<input
														type="text"
														className="form-control"
														id="val-zip1"
														autoComplete="off"
														name="zip"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.zip}
														placeholder="Enter Zip"
													/>
													<div
														id="val-zip1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.zip && errors.zip}
													</div>
												</div>
											</div>

											<button
												type="submit"
												className="btn me-2 btn-primary"
												disabled={showLoading}
											>
												Submit
											</button>
											<button
												type="button"
												className="btn btn-danger light"
												onClick={() => resetForm()}
											>
												Cancel
											</button>
										</form>
									)}
								</Formik>

								<ToastContainer />
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		errorMessage: state.brokers.errorMessage,
		successMessage: state.brokers.successMessage,
		showLoading: state.brokers.showLoading,
		singleBrokerData: state.brokers.singleBrokerData,
	};
};
export default connect(mapStateToProps)(UpdateBroker);
