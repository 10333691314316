import React, { Fragment, useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  trestleConfigAction,
  sparkConfigAction,
  mlsgridConfigAction,
  loadingToggleAction,
} from '../../../store/actions/ConfigActions';

const validateTrestleConfig = Yup.object().shape({
  clientid: Yup.string()
    .min(40, "Your clientid must consist of at least 40 characters ")
    .max(50, "Your clientid must consist of maximum 50 characters ")
    .required("Please provide a clientId"),
  clientsecret: Yup.string()
    .min(30, "Your clientsecret must be at least 30 characters long")
    .max(40, "Your clientsecret must be at maximum 40 characters long")
    .required("Please provide a clientSecret"),
});

const validateSparkConfig = Yup.object().shape({
  accesstoken: Yup.string()
    .min(20, "Your accesstoken must consist of at least 20 characters ")
    .max(30, "Your accesstoken must consist of maximum 30 characters ")
    .required("Please provide a accessToken"),
});

const validateMLSGridConfig = Yup.object().shape({
  accesstoken: Yup.string()
    .min(40, "Your accesstoken must consist of at least 40 characters ")
    .max(50, "Your accesstoken must consist of maximum 50 characters ")
    .required("Please provide a accessToken"),
  refreshtoken: Yup.string()
    .min(10, "Your refreshtoken must be at least 10 characters long")
    .max(40, "Your refreshtoken must be at maximum 40 characters long")
    .required("Please provide a refreshToken"),
});


const Configuration = (props) => {

  let user = JSON.parse(localStorage.getItem('userDetails'))
  let trestleConfig = user.trestleConfig;
  const isTrestleVerifiedTxt = (trestleConfig) ? 'Verified' : 'Not Verified';
  const isTrestleVerifiedIcon = (trestleConfig) ? 'fa-check' : 'fa-times';
  const isTrestleColor = (trestleConfig) ? 'btn-success' : 'btn-danger';

  let sparkConfig = user.sparkConfig;
  const isSparkVerifiedTxt = (sparkConfig) ? 'Verified' : 'Not Verified';
  const isSparkVerifiedIcon = (sparkConfig) ? 'fa-check' : 'fa-times';
  const isSparkColor = (sparkConfig) ? 'btn-success' : 'btn-danger';

  let mslGridConfig = user.mlsgridConfig;
  const isMLSGridVerifiedTxt = (mslGridConfig) ? 'Verified' : 'Not Verified';
  const isMLSGridVerifiedIcon = (mslGridConfig) ? 'fa-check' : 'fa-times';
  const isMLSGridColor = (mslGridConfig) ? 'btn-success' : 'btn-danger';

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleTrestleSubmit = (values, { setSubmitting }) => {

    dispatch(loadingToggleAction(true));
    dispatch(trestleConfigAction(values.clientid, values.clientsecret, navigate));
    setSubmitting(false);
  };

  const handleSparkSubmit = (values, { setSubmitting }) => {

    dispatch(loadingToggleAction(true));
    dispatch(sparkConfigAction(values.accesstoken, navigate));
    setSubmitting(false);
  };

  const handleMLSGridSubmit = (values, { setSubmitting }) => {

    dispatch(loadingToggleAction(true));
    dispatch(mlsgridConfigAction(values.accesstoken, values.refreshtoken, navigate));
    setSubmitting(false);
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu="Configuration"
        motherMenu="Home"
        pageContent="Configuration"
      />

      {props.showLoading && (
        <div className='loader-sec'>
          <div className="loader">Loading...</div>
        </div>
      )}

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Trestle Configuration</h4>

              <div className="custom-dropdown mb-0 dropdown">
                <Link to="#">
                  <button type="button" className={`me-2 btn btn-sm btn-rounded ${isTrestleColor}`}>
                    <span className="btn-icon-start text-info">
                      <i className={`fa ${isTrestleVerifiedIcon} color-info`}></i>
                    </span>{isTrestleVerifiedTxt}
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <Formik
                  initialValues={{ clientid: "", clientsecret: "" }}
                  validationSchema={validateTrestleConfig}
                  onSubmit={handleTrestleSubmit}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleTrestleSubmit,
                    isSubmitting,
                    resetForm
                  }) => (
                    <Form>
                      <div
                        className={`form-group mb-3 ${values.clientid
                          ? errors.clientid
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                          }`}
                      >
                        <div className="input-group">
                          <span className="input-group-text col-sm-6 col-md-2">
                            clientId
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            id="val-clientid1"
                            autoComplete="off"
                            placeholder="Enter a clientId"
                            name="clientid"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.clientid}
                          />
                          <div
                            id="val-clientid1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.clientid && errors.clientid}
                          </div>

                          <div
                            id="val-clientid1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>
                      <div
                        className={`form-group mb-3 ${values.clientsecret
                          ? errors.clientsecret
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                          }`}
                      >
                        <div className="input-group transparent-append mb-2">

                          <span className="input-group-text col-sm-6 col-md-2">
                            clientSecret
                          </span>

                          <input
                            type="text"
                            className="form-control"
                            id="val-clientsecret1"
                            autoComplete="off"
                            name="clientsecret"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.clientsecret}
                            placeholder="Enter a clientSecret"
                          />
                          <div
                            id="val-clientid1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.clientsecret && errors.clientsecret}
                          </div>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn me-2 btn-primary"
                        disabled={props.showLoading}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger light"
                        onClick={() => resetForm()}
                      >
                        Cancel
                      </button>
                    </Form>
                  )}
                </Formik>

                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Spark Configuration</h4>

              <div className="custom-dropdown mb-0 dropdown">
                <Link to="#">
                  <button type="button" className={`me-2 btn btn-sm btn-rounded ${isSparkColor}`}>
                    <span className="btn-icon-start text-info">
                      <i className={`fa ${isSparkVerifiedIcon} color-info`}></i>
                    </span>{isSparkVerifiedTxt}
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <Formik
                  initialValues={{ accesstoken: "" }}
                  validationSchema={validateSparkConfig}
                  onSubmit={handleSparkSubmit}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSparkSubmit,
                    isSubmitting,
                    resetForm
                  }) => (
                    <Form>
                      <div
                        className={`form-group mb-3 ${values.accesstoken
                          ? errors.clientid
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                          }`}
                      >
                        <div className="input-group">
                          <span className="input-group-text col-sm-6 col-md-2">
                            accessToken
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            id="val-accesstoken1"
                            autoComplete="off"
                            placeholder="Enter a accessToken"
                            name="accesstoken"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.accesstoken}
                          />
                          <div
                            id="val-accesstoken1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.accesstoken && errors.accesstoken}
                          </div>

                          <div
                            id="val-accesstoken1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn me-2 btn-primary"
                        disabled={props.showLoading}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger light"
                        onClick={() => resetForm()}
                      >
                        Cancel
                      </button>
                    </Form>
                  )}
                </Formik>

                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">MLSGrid Configuration</h4>

              <div className="custom-dropdown mb-0 dropdown">
                <Link to="#">
                  <button type="button" className={`me-2 btn btn-sm btn-rounded ${isMLSGridColor}`}>
                    <span className="btn-icon-start text-info">
                      <i className={`fa ${isMLSGridVerifiedIcon} color-info`}></i>
                    </span>{isMLSGridVerifiedTxt}
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <Formik
                  initialValues={{ accesstoken: "", refreshtoken: "" }}
                  validationSchema={validateMLSGridConfig}
                  onSubmit={handleMLSGridSubmit}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleMLSGridSubmit,
                    isSubmitting,
                    resetForm
                  }) => (
                    <Form>
                      <div
                        className={`form-group mb-3 ${values.accesstoken
                          ? errors.accesstoken
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                          }`}
                      >
                        <div className="input-group">
                          <span className="input-group-text col-sm-6 col-md-2">
                            accessToken
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            id="val-accesstoken1"
                            autoComplete="off"
                            placeholder="Enter a accessToken"
                            name="accesstoken"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.accesstoken}
                          />
                          <div
                            id="val-accesstoken1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.accesstoken && errors.accesstoken}
                          </div>

                          <div
                            id="val-clientid1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>
                      <div
                        className={`form-group mb-3 ${values.refreshtoken
                          ? errors.refreshtoken
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                          }`}
                      >
                        <div className="input-group transparent-append mb-2">
                          <span className="input-group-text col-sm-6 col-md-2">
                            refreshToken
                          </span>

                          <input
                            type="text"
                            className="form-control"
                            id="val-refreshtoken1"
                            autoComplete="off"
                            name="refreshtoken"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.refreshtoken}
                            placeholder="Enter a refreshToken"
                          />
                          <div
                            id="val-clientid1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.refreshtoken && errors.refreshtoken}
                          </div>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn me-2 btn-primary"
                        disabled={props.showLoading}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger light"
                        onClick={() => resetForm()}
                      >
                        Cancel
                      </button>
                    </Form>
                  )}
                </Formik>

                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>

    </Fragment >
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.config.errorMessage,
    successMessage: state.config.successMessage,
    showLoading: state.config.showLoading,
  };
};
export default connect(mapStateToProps)(Configuration);
// export default Configuration;
