
const initialState = {
    osOptions: [],
    allOSOptions: [],
    statusOptions: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function DownloadReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_OS_OPTIONS_SUCCESS':
            return {
                ...state,
                showLoading: false,
                osOptions: action.payload
            };
        case 'FETCH_OS_OPTIONS_FAILURE':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        case 'FETCH_ALL_OS_OPTIONS_SUCCESS':
            return {
                ...state,
                showLoading: false,
                allOSOptions: action.payload
            };
        case 'FETCH_ALL_OS_OPTIONS_FAILURE':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        case 'FETCH_STATUS_OPTIONS_SUCCESS':
            return {
                ...state,
                showLoading: false,
                statusOptions: action.payload
            };
        case 'FETCH_STATUS_OPTIONS_FAILURE':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        case 'DOWNLOAD_CONFIRM_ACTION':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        case 'DOWNLOAD_CONFIRMED_ACTION':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        case 'DOWNLOAD_FAILED_ACTION':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        case 'DOWNLOAD_LOADING_TOGGLE_ACTION':
            return {
                ...state,
                showLoading: action.payload,
            };
        default:
            return state;
    }
}


