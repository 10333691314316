import {
    getAgentsData,
    fetchSingleAgent,
    createAgent,
    updateAgent,
    deleteAgent,
} from '../../services/AgentService';

import {
    formatError,
    showToast
} from '../../services/AuthService';


export function getAgentsDataAction(currentPage, limit) {

    const agentSuccessMessage = JSON.parse(localStorage.getItem('agentSuccessMessage'));
    if (agentSuccessMessage) {
        showToast(agentSuccessMessage);
        localStorage.removeItem('agentSuccessMessage');
    }

    return (dispatch) => {
        getAgentsData(currentPage, limit)
            .then((response) => {
                dispatch(fetchAgentsSuccess(response.data.result));
            })
            .catch((error) => {
                dispatch(fetchAgentsFailure(error.response.data));
            });
    };
}

export function createAgentAction(params, navigate) {
    return (dispatch) => {
        createAgent(params)
            .then((response) => {
                localStorage.setItem('agentSuccessMessage', JSON.stringify(response.data));
                dispatch(agentSignupSuccessAction(response.data));
                navigate('/agents');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(agentSignupFailedAction(errorMessage));
            });
    };
}

export function updateAgentAction(params, navigate) {
    return (dispatch) => {
        updateAgent(params)
            .then((response) => {
                localStorage.setItem('agentSuccessMessage', JSON.stringify(response.data));
                dispatch(agentSignupSuccessAction(response.data));
                navigate('/agents');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(agentSignupFailedAction(errorMessage));
            });
    };
}

export function deleteAgentAction(id, navigate) {
    return (dispatch) => {
        deleteAgent(id)
            .then((response) => {
                const successMessage = showToast(response.data);
                dispatch(agentSignupSuccessAction(response.data));
                navigate('/agents');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(agentSignupFailedAction(errorMessage));
            });
    };
}

export const fetchAgentsSuccess = options => {
    return {
        type: 'FETCH_AGENTS_SUCCESS',
        payload: options
    };
};

export const fetchAgentsFailure = error => {
    return {
        type: 'FETCH_AGENTS_FAILURE',
        payload: error
    };
};

export function agentSignupSuccessAction(payload) {
    return {
        type: 'AGENT_SIGNUP_CONFIRMED_ACTION',
        payload,
    };
}

export function agentSignupFailedAction(message) {
    return {
        type: 'AGENT_SIGNUP_FAILED_ACTION',
        payload: message,
    };
}

export function fetchSingleAgentAction(id) {
    return (dispatch) => {
        fetchSingleAgent(id)
            .then((response) => {
                dispatch(fetchSingleAgentSuccess(response.data.result));
            })
            .catch((error) => {
                dispatch(fetchSingleAgentFailure(error.response));
            });
    };
}

export const fetchSingleAgentSuccess = options => {
    return {
        type: 'FETCH_SINGLE_AGENT_SUCCESS',
        payload: options
    };
};

export const fetchSingleAgentFailure = error => {
    return {
        type: 'FETCH_SINGLE_AGENT_FAILURE',
        payload: error
    };
};

export function loadingToggleAction(status) {
    return {
        type: 'AGENTS_LOADING_TOGGLE_ACTION',
        payload: status,
    };
}

