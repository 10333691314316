import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';

import './agentlist.css';
import {
	Row,
	Col,
	Card,
	Table,
} from "react-bootstrap";

import {
	getAgentsDataAction,
	deleteAgentAction,
	loadingToggleAction,
} from '../../../store/actions/AgentActions';

export const AgentListing = ({ agentData, showLoading }) => {

	const [agents, setAgents] = useState([]);
	const [page, setPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [hasMore, setHasMore] = useState(true);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [totalPages, setTotalPages] = useState(0);
	const [totalRecords, setTotalRecords] = useState(0);
	const [fromRecords, setFromRecords] = useState(0);
	const [toRecords, setToRecords] = useState(0);
	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	useEffect(() => {
		dispatch(loadingToggleAction(true))
		if (currentPage !== null) {
			dispatch(getAgentsDataAction(currentPage, limit));
		}
	}, [currentPage]);

	useEffect(() => {

		if (agentData.docs) {
			if (agentData.docs.length === 0) {
				setHasMore(false);
			} else {

				setAgents([...agentData.docs]);
				setPage(page + 1);

				setHasMore(agentData.hasNextPage);
				setTotalPages(agentData.totalPages);
				setTotalRecords(agentData.totalDocs);
				setFromRecords(agentData.pagingCounter);
				setToRecords(agentData.pagingCounter + limit - 1);
			}
		}

	}, [agentData.docs]);

	const handleDelete = (id) => {
		swal({
			title: "Are you sure?",
			text:
				"Once deleted, you will not be able to recover this record!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(deleteAgentAction(id, navigate));
				dispatch(loadingToggleAction(true));
				dispatch(getAgentsDataAction(currentPage, limit));
			}
		})
	};

	const renderPaginationButtons = () => {
		const buttonsToShow = 5; // Number of pagination buttons to show
		const halfButtons = Math.floor(buttonsToShow / 2);
		const pages = [];

		for (let i = Math.max(1, currentPage - halfButtons); i <= Math.min(totalPages, currentPage + halfButtons); i++) {
			pages.push(i);
		}

		return (
			<div className="d-flex align-items-center justify-content-between  flex-wrap">
				{totalRecords > toRecords ? (
					<h5>Showing {fromRecords} to {toRecords} of {totalRecords} entries</h5>
				) : (
					<h5>Showing {fromRecords} to {totalRecords} of {totalRecords} entries</h5>
				)}
				<ul className="pagination align-items-center">
					{currentPage > 1 && (
						<>
							<li className="page-item page-indicator">
								<button className="btn btn-primary btn-sm me-2" onClick={() => handlePageChange(1)}>First</button>
							</li>
							<li className="page-item page-indicator">
								<button className="btn btn-primary btn-sm me-2" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
							</li>
						</>
					)}

					{pages.map((pageNumber, index) => (
						<li className="page-item" key={index}>
							<button
								className={`btn btn-sm me-2 ${currentPage === pageNumber ? 'btn-danger' : 'btn-primary'}`}
								key={pageNumber}
								onClick={() => handlePageChange(pageNumber)}
								disabled={currentPage === pageNumber}
							>
								{pageNumber}
							</button>
						</li>
					))}

					{currentPage < totalPages && (
						<>
							<li className="page-item page-indicator">
								<button className="btn btn-primary btn-sm me-2" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
							</li>

							<li className="page-item page-indicator">
								<button className="btn btn-primary btn-sm me-2" onClick={() => handlePageChange(totalPages)}>Last</button>
							</li>
						</>
					)}
				</ul>
			</div>
		);
	};

	return (
		<>
			<PageTitle
				activeMenu="Agents"
				motherMenu="Home"
				pageContent="Agents"
			/>

			{showLoading && (
				<div className='loader-sec'>
					<div className="loader">Loading...</div>
				</div>
			)}

			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Agents</Card.Title>
							<div className="custom-dropdown mb-0 dropdown">
								<Link to="/agents/update-agent">
									<button type="button" className="me-2 btn btn-primary btn-sm btn-rounded">
										<span className="btn-icon-start text-info">
											<i className="fa fa-plus color-info"></i>
										</span>Add
									</button>
								</Link>
							</div>
						</Card.Header>
						<Card.Body>
							<Table responsive>
								<thead>
									<tr>
										<th>
											<strong>First Name</strong>
										</th>
										<th>
											<strong>Last Name</strong>
										</th>
										<th>
											<strong>Email</strong>
										</th>
										<th>
											<strong>Action</strong>
										</th>
									</tr>
								</thead>
								<tbody>
									{showLoading ? (
										<tr>
											<td colSpan="3" className="text-center">Loading...</td>
										</tr>
									) : (agents && agents.length > 0) ? (
										agents.map((agent, index) => (
											<tr key={index}>
												<td>
													<div className="d-flex align-items-center">
														<span className="w-space-no">{agent.first_name}</span>
													</div>
												</td>
												<td>
													<div className="d-flex align-items-center">
														<span className="w-space-no">{agent.last_name}</span>
													</div>
												</td>
												<td>{agent.email}</td>
												<td>
													<div className="d-flex">
														<Link
															to={`/agents/update-agent/${agent._id}`}
															className="btn btn-primary shadow btn-xs sharp me-1"
														>
															<i className="fas fa-pen"></i>
														</Link>
														<button
															type="button"
															className="btn btn-danger shadow btn-xs sharp"
															onClick={() => handleDelete(agent._id)}
														>
															<i className="fa fa-trash"></i>
														</button>
													</div>
												</td>
											</tr>
										))
									) : (
										<tr>
											<td colSpan="4" className="text-center">No record found.</td>
										</tr>
									)}
								</tbody>
							</Table>

							{agents && agents.length > 0 && renderPaginationButtons()}

							<ToastContainer />
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		errorMessage: state.agents.errorMessage,
		successMessage: state.agents.successMessage,
		showLoading: state.agents.showLoading,
		agentData: state.agents.agentData,
	};
};
export default connect(mapStateToProps)(AgentListing);