import React, { Fragment, useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
// import styles
import { Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadingToggleAction, updateUserAction, fetchSingleUserAction } from '../../../store/actions/UserActions';
import Select from "react-select";
import PageTitle from "../../layouts/PageTitle";
import './userlist.css';


const validateConfig = Yup.object().shape({
	name: Yup.string()
		.min(5, "Your name must consist of at least 5 characters ")
		.max(40, "Your name must consist of maximum 40 characters ")
		.required("Please provide a Name"),
	email: Yup.string()
		.min(5, "Your Email must consist of at least 5 characters ")
		.max(30, "Your Email must consist of maximum 40 characters ")
		.required("Please provide a Email"),
	password: Yup.string()
		.required('Password is required')
		.min(8, 'Password must be at least 8 characters')
		.max(25, 'Password must consist of maximum 25 characters'),
	// .matches(
	// 	/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/,
	// 	'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
	// ),
	mobile: Yup.string()
		.matches(/^[0-9]{10}$/, 'Mobile number is not valid')
		.min(10, "Your mobile number must consist of at least 5 characters ")
		.max(10, "Your Mobile number must consist of maximum 10 characters  ")
		.required("Please provide a Mobile Number"),
	address: Yup.string()
		.min(10, "Your address must be at least 10 characters long")
		.max(100, "Your address must be at maximum 100 characters long")
		.required("Please provide a Address"),
	area: Yup.string()
		.min(3, "Your area must be at least 3 characters long")
		.max(50, "Your area must be at maximum 50 characters long")
		.required("Please provide a Area"),
	city: Yup.string()
		.min(3, "Your city must be at least 3 characters long")
		.max(50, "Your city must be at maximum 50 characters long")
		.required("Please provide a City"),
	state: Yup.string()
		.min(3, "Your state must be at least 3 characters long")
		.max(50, "Your state must be at maximum 50 characters long")
		.required("Please provide a State"),
	country: Yup.string()
		.min(3, "Your country must be at least 3 characters long")
		.max(50, "Your country must be at maximum 50 characters long")
		.required("Please provide a Country"),
	zip: Yup.string()
		.min(5, "Your zip code must be at least 5 characters long")
		.max(6, "Your zip code must be at maximum 6 characters long")
		.required("Please provide a Zip Code"),
});

const UpdateUser = ({ singleUserData }) => {

	let user = JSON.parse(localStorage.getItem('userDetails'))
	let role = user.authorities.name;
	let create_user_role = (role == 'superadmin') ? 'Broker' : 'Agent';
	const { id } = useParams();

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userData = JSON.parse(localStorage.getItem('userDetails'));

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [mobile, setMobile] = useState('');
	const [address, setAddress] = useState('');
	const [area, setArea] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [country, setCountry] = useState('');
	const [zip, setZip] = useState('');

	useEffect(() => {

		dispatch(fetchSingleUserAction(id));
		if (typeof singleUserData !== 'undefined') {
			setName(singleUserData.name);
		}
	}, [id, dispatch]);


	const handleSubmit = (values, { setSubmitting }) => {
		dispatch(loadingToggleAction(true));
		dispatch(updateUserAction(values, navigate));
	};

	return (
		<Fragment>
			<PageTitle activeMenu="User" motherMenu="App" />

			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">Update User</h4>
							<div className="custom-dropdown mb-0 dropdown">
								<Link to="/users">
									<button type="button" className="me-2 btn btn-primary btn-sm btn-rounded">
										<span className="btn-icon-start text-info">
											<i className="fa fa-arrow-left color-info"></i>
										</span>Back
									</button>
								</Link>
							</div>
						</div>
						<div className="card-body">
							<div className="basic-form">
								<Formik
									initialValues={{
										parent: userData._id,
										role: create_user_role,
										name: (name) ? name : "",
										email: (email) ? email : "",
										password: "",
										mobile: (mobile) ? mobile : "",
										address: (address) ? address : "",
										area: (area) ? area : "",
										city: (city) ? city : "",
										state: (state) ? state : "",
										country: (country) ? country : "",
										zip: (zip) ? zip : "",
									}}
									validationSchema={validateConfig}
									onSubmit={handleSubmit}
								>
									{({
										values,
										errors,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										resetForm
									}) => (
										<form onSubmit={handleSubmit}>
											<div
												className={`form-group mb-3 ${values.name
													? errors.name
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												{/* <label className="text-label">Name</label> */}
												<div className="input-group">
													<span className="input-group-text col-2">
														Name
													</span>
													<input
														type="text"
														className="form-control"
														id="val-name1"
														autoComplete="off"
														placeholder="Enter a Name"
														name="name"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.name}
													/>
													<input
														type="hidden"
														className="form-control"
														id="val-id1"
														autoComplete="off"
														name="role"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.role}
													/>
													<div
														id="val-name1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.name && errors.name}
													</div>

													<div
														id="val-name1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													/>
												</div>
											</div>

											<div
												className={`form-group mb-3 ${values.email
													? errors.email
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												{/* <label className="text-label">Name</label> */}
												<div className="input-group">
													<span className="input-group-text col-2">
														Email
													</span>
													<input
														type="email"
														className="form-control"
														id="val-name3"
														autoComplete="off"
														placeholder="Enter a Email"
														name="email"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.email}
													/>
													<div
														id="val-name3-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.email && errors.email}
													</div>

													<div
														id="val-name3-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													/>
												</div>
											</div>

											<div
												className={`form-group mb-3 ${values.password
													? errors.password
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												{/* <label className="text-label">Name</label> */}
												<div className="input-group">
													<span className="input-group-text col-2">
														Password
													</span>
													<input
														type="password"
														className="form-control"
														id="val-password3"
														autoComplete="new-password"
														placeholder="Enter a Password"
														name="password"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.password}
													/>
													<div
														id="val-password3-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.password && errors.password}
													</div>

													<div
														id="val-password3-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													/>
												</div>
											</div>

											<div
												className={`form-group mb-3 ${values.mobile
													? errors.mobile
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												{/* <label className="text-label">Name</label> */}
												<div className="input-group">
													<span className="input-group-text col-2">
														Mobile Number
													</span>
													<input
														type="tel"
														className="form-control"
														id="val-mobile"
														minLength={10}
														maxLength={10}
														autoComplete="off"
														placeholder="Enter a Mobile Number"
														name="mobile"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.mobile}
													/>
													<div
														id="val-name2-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.mobile && errors.mobile}
													</div>

													<div
														id="val-name2-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													/>
												</div>
											</div>
											<div
												className={`form-group mb-3 ${values.address
													? errors.address
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												{/* <label className="text-label">Address</label> */}
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														Address
													</span>

													<input
														type="text"
														className="form-control"
														id="val-address1"
														autoComplete="off"
														name="address"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.address}
														placeholder="Enter a Address"
													/>
													<div
														id="val-name1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.address && errors.address}
													</div>
												</div>
											</div>
											<div
												className={`form-group mb-3 ${values.area
													? errors.area
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												{/* <label className="text-label">Area</label> */}
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														Area
													</span>

													<input
														type="text"
														className="form-control"
														id="val-area1"
														autoComplete="off"
														name="area"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.area}
														placeholder="Enter a Area"
													/>
													<div
														id="val-name1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.area && errors.area}
													</div>
												</div>
											</div>
											<div
												className={`form-group mb-3 ${values.city
													? errors.city
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												{/* <label className="text-label">City</label> */}
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														City
													</span>

													<input
														type="text"
														className="form-control"
														id="val-city1"
														autoComplete="off"
														name="city"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.city}
														placeholder="Enter a City"
													/>													<div
														id="val-name1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.city && errors.city}
													</div>
												</div>
											</div>

											<div
												className={`form-group mb-3 ${values.state
													? errors.state
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												{/* <label className="text-label">State</label> */}
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														State
													</span>

													<input
														type="text"
														className="form-control"
														id="val-state1"
														autoComplete="off"
														name="state"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.state}
														placeholder="Enter a State"
													/>													<div
														id="val-name1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.state && errors.state}
													</div>
												</div>
											</div>

											<div
												className={`form-group mb-3 ${values.country
													? errors.country
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												{/* <label className="text-label">Country</label> */}
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														Country
													</span>

													<input
														type="text"
														className="form-control"
														id="val-country1"
														autoComplete="off"
														name="country"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.country}
														placeholder="Enter a Country"
													/>													<div
														id="val-name1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.country && errors.country}
													</div>
												</div>
											</div>

											<div
												className={`form-group mb-3 ${values.zip
													? errors.zip
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												{/* <label className="text-label">Zip</label> */}
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														Zip
													</span>

													<input
														type="text"
														className="form-control"
														id="val-zip1"
														autoComplete="off"
														name="zip"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.zip}
														placeholder="Enter a Zip"
													/>													<div
														id="val-name1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.zip && errors.zip}
													</div>
												</div>
											</div>

											<button
												type="submit"
												className="btn me-2 btn-primary"
											// disabled={showLoading}
											>
												Submit
											</button>
											<button
												type="button"
												className="btn btn-danger light"
												onClick={() => resetForm()}
											>
												Cancel
											</button>
										</form>
									)}
								</Formik>

								<ToastContainer />
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

const mapStateToProps = (state) => {

	console.log('state', state.users.singleUserData)
	return {
		errorMessage: state.users.errorMessage,
		successMessage: state.users.successMessage,
		showLoading: state.users.showLoading,
		singleUserData: state.users.singleUserData,
	};
};
export default connect(mapStateToProps)(UpdateUser);
