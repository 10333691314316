import {
    getBrokersData,
    fetchSingleBroker,
    createBroker,
    updateBroker,
    deleteBroker,
} from '../../services/BrokerService';

import {
    formatError,
    showToast
} from '../../services/AuthService';


export function getBrokersDataAction(currentPage, limit) {

    const brokerSuccessMessage = JSON.parse(localStorage.getItem('brokerSuccessMessage'));
    if (brokerSuccessMessage) {
        showToast(brokerSuccessMessage);
        localStorage.removeItem('brokerSuccessMessage');
    }

    return (dispatch) => {
        getBrokersData(currentPage, limit)
            .then((response) => {
                dispatch(fetchBrokersSuccess(response.data.result));
            })
            .catch((error) => {
                dispatch(fetchBrokersFailure(error.response.data));
            });
    };
}

export function createBrokerAction(params, navigate) {
    return (dispatch) => {
        createBroker(params)
            .then((response) => {
                localStorage.setItem('brokerSuccessMessage', JSON.stringify(response.data));
                dispatch(brokerSignupSuccessAction(response.data));
                navigate('/brokers');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(brokerSignupFailedAction(errorMessage));
            });
    };
}

export function updateBrokerAction(params, navigate) {
    return (dispatch) => {
        updateBroker(params)
            .then((response) => {
                localStorage.setItem('brokerSuccessMessage', JSON.stringify(response.data));
                dispatch(brokerSignupSuccessAction(response.data));
                navigate('/brokers');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(brokerSignupFailedAction(errorMessage));
            });
    };
}

export function deleteBrokerAction(id, navigate) {
    return (dispatch) => {
        deleteBroker(id)
            .then((response) => {
                const successMessage = showToast(response.data);
                dispatch(brokerSignupSuccessAction(response.data));
                navigate('/brokers');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(brokerSignupFailedAction(errorMessage));
            });
    };
}

export const fetchBrokersSuccess = options => {
    return {
        type: 'FETCH_BROKERS_SUCCESS',
        payload: options
    };
};

export const fetchBrokersFailure = error => {
    return {
        type: 'FETCH_BROKERS_FAILURE',
        payload: error
    };
};

export function brokerSignupSuccessAction(payload) {
    return {
        type: 'BROKER_SIGNUP_CONFIRMED_ACTION',
        payload,
    };
}

export function brokerSignupFailedAction(message) {
    return {
        type: 'BROKER_SIGNUP_FAILED_ACTION',
        payload: message,
    };
}

export function fetchSingleBrokerAction(id) {
    return (dispatch) => {
        fetchSingleBroker(id)
            .then((response) => {
                dispatch(fetchSingleBrokerSuccess(response.data.result));
            })
            .catch((error) => {
                dispatch(fetchSingleBrokerFailure(error.response));
            });
    };
}

export const fetchSingleBrokerSuccess = options => {
    return {
        type: 'FETCH_SINGLE_BROKER_SUCCESS',
        payload: options
    };
};

export const fetchSingleBrokerFailure = error => {
    return {
        type: 'FETCH_SINGLE_BROKER_FAILURE',
        payload: error
    };
};

export function loadingToggleAction(status) {
    return {
        type: 'BROKERS_LOADING_TOGGLE_ACTION',
        payload: status,
    };
}

