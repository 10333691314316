
const initialState = {
    propertyData: [],
    propertyTrestleData: [],
    propertySparkData: [],
    propertyMLSGridData: [],
    singlePropertyData: [],
    importTrestleData: [],
    importSparkData: [],
    importMLSGridData: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function PropertyReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_PROPERTIES_SUCCESS':
            if (action.payload && action.payload.docs && action.payload.docs.length > 0) {
                return {
                    ...state,
                    showLoading: false,
                    propertyData: action.payload
                };
            }
        case 'FETCH_PROPERTIES_FAILURE':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };

        case 'FETCH_SINGLE_PROPERTY_SUCCESS':
            return {
                ...state,
                showLoading: false,
                singlePropertyData: action.payload
            };
        case 'FETCH_SINGLE_PROPERTY_FAILURE':
            return {
                ...state,
                showLoading: false,
                errorMessage: action.payload.data
            };
        case 'DOWNLOAD_LOADING_TOGGLE_ACTION':
            return {
                ...state,
                showLoading: action.payload,
            };
        case 'PROPERTY_LOADING_TOGGLE_ACTION':
            return {
                ...state,
                showLoading: action.payload,
            };
        case 'FETCH_TRESTLE_IMPORT_SUCCESS':
            if (action.payload && action.payload.docs && action.payload.docs.length > 0) {
                return {
                    ...state,
                    showLoading: false,
                    importTrestleData: action.payload
                };
            }
        case 'FETCH_TRESTLE_IMPORT_FAILURE':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        case 'FETCH_SPARK_IMPORT_SUCCESS':
            if (action.payload && action.payload.docs && action.payload.docs.length > 0) {
                return {
                    ...state,
                    showLoading: false,
                    importSparkData: action.payload
                };
            }
        case 'FETCH_SPARK_IMPORT_FAILURE':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        case 'FETCH_MLSGRID_IMPORT_SUCCESS':
            if (action.payload && action.payload.docs && action.payload.docs.length > 0) {
                return {
                    ...state,
                    showLoading: false,
                    importMLSGridData: action.payload
                };
            }
        case 'FETCH_MLSGRID_IMPORT_FAILURE':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
}


