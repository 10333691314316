import { applyMiddleware, combineReducers, compose, createStore, } from 'redux';
import PostsReducer from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import todoReducers from './reducers/Reducers';
import { ConfigReducer } from './reducers/ConfigReducer';
import { PropertyReducer } from './reducers/PropertyReducer';
import { TrestleReducer } from './reducers/TrestleReducer';
import { SparkReducer } from './reducers/SparkReducer';
import { MLSGridReducer } from './reducers/MLSGridReducer';
import { DownloadReducer } from './reducers/DownloadReducer';
import { UserReducer } from './reducers/UserReducer';
import { AgentReducer } from './reducers/AgentReducer';
import { BrokerReducer } from './reducers/BrokerReducer';
import { reducer as reduxFormReducer } from 'redux-form';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    posts: PostsReducer,
    config: ConfigReducer,
    property: PropertyReducer,
    trestle: TrestleReducer,
    spark: SparkReducer,
    mlsgrid: MLSGridReducer,
    groupby: DownloadReducer,
    users: UserReducer,
    agents: AgentReducer,
    brokers: BrokerReducer,
    auth: AuthReducer,
    todoReducers,
    form: reduxFormReducer,

});

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware));
