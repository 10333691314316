import React, { Fragment, useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Card, Col, Alert, Button } from "react-bootstrap";

import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';

import Select from "react-select";
import { Slider, RangeSlider } from 'rsuite';
// import "nouislider/distribute/nouislider.css";
import 'rsuite/dist/rsuite.min.css';


import {
  getGroupByDataAction,
  loadingToggleAction,
  downloadDataAction,
} from '../../../store/actions/DownloadActions';


const CustomClearText = () => "clear all";
const ClearIndicator = (props) => {

  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: "pointer",
  color: state.isFocused ? "blue" : "black",
});


const validateConfig = Yup.object().shape({
  OriginatingSystemName: Yup.array()
    .min(1, "Select at least one Originating System Name")
    .required('Please select one OriginatingSystemName'),
  MlsStatus: Yup.array()
    .min(1, "Select at least one Mls Status")
    .required('Please select one MlsStatus.'),
});

const DownloadData = (props) => {

  const isFirstRender = useRef(true);
  let user = JSON.parse(localStorage.getItem('userDetails'))
  let trestleConfig = user.trestleConfig;
  let sparkConfig = user.sparkConfig;
  let mlsgridConfig = user.mlsgridConfig;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedDateTime, setSelectedDateTime] = useState(moment());
  const [osOptions, setOSOptions] = useState(props.osOptions);
  const [statusOptions, setStatusOptions] = useState(props.statusOptions);
  const [valuePrice, setValuePrice] = React.useState([0, 100000]);
  const [importStart, setImportStart] = useState(false);
  const [radioSelectOption, setRadioSelectOption] = useState('trestle');
  const [osSelectedOption, setOsSelectedOption] = useState(null);
  const [statusSelectedOptions, setStatusSelectedOptions] = useState(null);

  const handleRadioChange = (event) => {
    setOSOptions([]);
    setStatusOptions([]);

    setOsSelectedOption(null);
    setStatusSelectedOptions(null);

    setRadioSelectOption(event.target.value);
  };

  useEffect(() => {
    setOSOptions([]);
    setStatusOptions([]);

    if (trestleConfig && radioSelectOption == 'trestle') {
      dispatch(loadingToggleAction(true));
      dispatch(getGroupByDataAction('OriginatingSystemName', radioSelectOption));
      dispatch(getGroupByDataAction('MlsStatus', radioSelectOption));
    }

    if (sparkConfig && radioSelectOption == 'spark') {
      dispatch(loadingToggleAction(true));
      dispatch(getGroupByDataAction('OriginatingSystemName', radioSelectOption));
      dispatch(getGroupByDataAction('MlsStatus', radioSelectOption));
    }

    if (mlsgridConfig && radioSelectOption == 'mlsgrid') {
      dispatch(loadingToggleAction(true));
      dispatch(getGroupByDataAction('OriginatingSystemName', radioSelectOption));
      dispatch(getGroupByDataAction('MlsStatus', radioSelectOption));
    }

  }, [dispatch, radioSelectOption]);

  useEffect(() => {

    let newOsOptions = []
    let newStatusOptions = []

    if (radioSelectOption == 'mlsgrid') {
      props.osOptions.map((val, index) => {
        newOsOptions.push({ value: Object.keys(val)[0], label: Object.values(val)[0] })
      })
      setOSOptions(newOsOptions)

      props.statusOptions.map((val, index) => {
        newStatusOptions.push({ value: Object.keys(val)[0], label: Object.values(val)[0] })
      })
      setStatusOptions(newStatusOptions)

    } else {
      props.osOptions.map((val, index) => {
        newOsOptions.push({ value: val, label: val })
      })
      setOSOptions(newOsOptions)

      props.statusOptions.map((val, index) => {
        newStatusOptions.push({ value: val, label: val })
      })
      setStatusOptions(newStatusOptions)
    }
  }, [props.osOptions, props.statusOptions]);

  const handleSubmit = (values, { setSubmitting }) => {

    const OriginatingSystemArray = Array.from(statusSelectedOptions)
      .map(option => option.value)

    const MlsStatusArray = Array.from(osSelectedOption)
      .map(option => option.value)

    const dateObj = new Date(values.ModificationTimestamp);
    const formattedDate = dateObj.toISOString().slice(0, 19).replace('T', ' ');

    const PropertyType = radioSelectOption;

    dispatch(loadingToggleAction(true));
    dispatch(downloadDataAction(PropertyType, OriginatingSystemArray, MlsStatusArray, values.minPrice, values.maxPrice, formattedDate, importStart, navigate));
  };
  // const { osOptions } = useSelector(state)
  // const osOptions = useSelector((state) => state.osOptions)
  return (
    <Fragment>
      <PageTitle
        activeMenu="Download Data"
        motherMenu="Home"
        pageContent="Download Data"
      />

      {props.showLoading && (
        <div className='loader-sec'>
          <div className="loader">Loading...</div>
        </div>
      )}

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Download Data</h4>
            </div>
            {!trestleConfig && !sparkConfig && !mlsgridConfig ? (
              <div className="card-body">
                <Alert variant="danger" className="solid alert-square">
                  <strong>Error! </strong> Please confirm Configuration first ..
                </Alert>
              </div>

            ) : (
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      propertyType: "Trestle",
                      minPrice: 0,
                      maxPrice: 100000,
                      OriginatingSystemName: [],
                      MlsStatus: [],
                      ModificationTimestamp: selectedDateTime
                    }}
                    validationSchema={validateConfig}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      setFieldValue,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      resetForm
                    }) => (
                      <Form onSubmit={handleSubmit}>

                        <div className="row">
                          <div
                            className={`form-group mb-3 ${values.propertyType
                              ? errors.propertyType
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                              }`}
                          >

                            <div className="mb-3 row">
                              <label className="col-sm-2 col-form-label">Property Type</label>
                              <div className="col-sm-6">
                                {trestleConfig &&
                                  <label className="radio-inline me-3">
                                    <input
                                      type="radio"
                                      name="propertyType"
                                      onChange={handleRadioChange}
                                      value="trestle"
                                      defaultChecked
                                    /> Trestle
                                  </label>
                                }
                                {sparkConfig &&
                                  <label className="radio-inline me-3">
                                    <input
                                      type="radio"
                                      name="propertyType"
                                      onChange={handleRadioChange}
                                      value="spark"
                                    // disabled
                                    /> Spark
                                  </label>
                                }
                                {mlsgridConfig &&
                                  <label className="radio-inline me-3">
                                    <input
                                      type="radio"
                                      name="propertyType"
                                      onChange={handleRadioChange}
                                      value="mlsgrid"
                                    /> MLSGrid
                                  </label>
                                }
                              </div>
                            </div>
                          </div>

                          <div
                            className={`form-group mb-3 ${values.OriginatingSystemName
                              ? errors.OriginatingSystemName
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                              }`}
                          >
                            <div className="mb-3 row">
                              <label className="col-sm-2 col-form-label col-form-label-sm">Operating System</label>
                              <div className="col-sm-7">
                                <Field name="OriginatingSystemName">
                                  {({ field, form }) => (
                                    <Select
                                      closeMenuOnSelect={false}
                                      components={{ ClearIndicator }}
                                      styles={{ clearIndicator: ClearIndicatorStyles }}
                                      value={osSelectedOption}
                                      name="OriginatingSystemName1"
                                      isMulti
                                      options={osOptions}
                                      onChange={(selectedOption) => {
                                        form.setFieldValue(
                                          'OriginatingSystemName',
                                          selectedOption,
                                        )
                                        setOsSelectedOption(selectedOption);
                                      }
                                      }
                                    />
                                  )}
                                </Field>
                                <div
                                  id="val-OriginatingSystemName-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.OriginatingSystemName && errors.OriginatingSystemName}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className={`form-group mb-3 ${values.MlsStatus
                              ? errors.MlsStatus
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                              }`}
                          >
                            <div className="mb-3 row">
                              <label className="col-sm-2 col-form-label col-form-label-sm">MLS Status</label>
                              <div className="col-sm-7">

                                <Field name="MlsStatus">
                                  {({ field, form }) => (
                                    <Select
                                      closeMenuOnSelect={false}
                                      components={{ ClearIndicator }}
                                      styles={{ clearIndicator: ClearIndicatorStyles }}
                                      value={statusSelectedOptions}
                                      name="MlsStatus"
                                      isMulti
                                      options={statusOptions}
                                      onChange={(selectedOption) => {
                                        form.setFieldValue(
                                          'MlsStatus',
                                          [selectedOption],
                                        )
                                        setStatusSelectedOptions(selectedOption);
                                      }
                                      }
                                    />
                                  )}
                                </Field>

                                <div
                                  id="val-MlsStatus-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.MlsStatus && errors.MlsStatus}
                                </div>
                              </div>
                            </div>
                          </div>

                          {radioSelectOption != 'mlsgrid' && (
                            <div className="mb-3 row" style={{ rowGap: '20px' }}>
                              <label className="col-sm-2 col-form-label col-form-label-sm">Price Range</label>
                              <div className="col-sm-3 position-relative">
                                <RangeSlider
                                  defaultValue={[0, 100000]}
                                  min={0}
                                  max={100000}
                                  progress
                                  // style={{ marginTop: 16 }}
                                  value={valuePrice}
                                  onChange={value => {
                                    setValuePrice(value);
                                    setFieldValue('minPrice', value[0]);
                                    setFieldValue('maxPrice', value[1]);
                                  }}
                                />
                              </div>
                              <div className="col-sm-2">
                                <input
                                  type="text"
                                  id="val-minPrice"
                                  name="minPrice"
                                  className="form-control form-control-sm"
                                  placeholder="Min Price"
                                  value={valuePrice[0]}
                                  readOnly
                                />
                              </div>
                              <div className="col-sm-2">
                                <input
                                  type="text"
                                  id="val-maxPrice"
                                  name="maxPrice"
                                  className="form-control form-control-sm"
                                  placeholder="Max Price"
                                  value={valuePrice[1]}
                                  readOnly
                                />
                              </div>
                            </div>
                          )}

                          <div className="mb-3 row">
                            <label className="col-sm-2 col-form-label col-form-label-sm">From Datetime</label>
                            <div className="col-sm-3">
                              <DateTime
                                name="ModificationTimestamp"
                                value={selectedDateTime}
                                onChange={date => {
                                  if (date) {
                                    setSelectedDateTime(date);
                                  } else {
                                    setSelectedDateTime(moment());
                                  }
                                  setFieldValue('ModificationTimestamp', date);
                                }}
                                maxDate={moment()}
                                inputProps={
                                  {
                                    className: 'form-control form-control-sm',
                                    name: 'ModificationTimestamp',
                                    id: 'ModificationTimestamp'
                                  }
                                }
                                timeFormat="HH:mm"
                                dateFormat="YYYY-MM-DD"
                              />
                            </div>
                          </div>
                        </div>

                        <button
                          type="submit"
                          className="btn me-2 btn-primary"
                          // disabled={isSubmitting}
                          disabled={props.showLoading}
                        >
                          Download Data
                        </button>
                      </Form>
                    )}
                  </Formik>

                  <ToastContainer />

                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment >
  );
};

const mapStateToProps = (state) => {
  // console.log('state', state.groupby.showLoading)
  return {
    errorMessage: state.config.errorMessage,
    successMessage: state.config.successMessage,
    showLoading: state.groupby.showLoading,
    osOptions: state.groupby.osOptions,
    statusOptions: state.groupby.statusOptions,
  };
};
export default connect(mapStateToProps)(DownloadData);

