import axios from 'axios';
import { API_URL } from '../apiConfig';

export function authHeader() {
    let user = JSON.parse(localStorage.getItem('userDetails'))

    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token }
    } else {
        return {}
    }
}

export function getBrokersData(page, limit) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return axios.get(
        `${API_URL}/user/get/brokers?page=${page}&limit=${limit}`,
        requestOptions,
    );
}

export function fetchSingleBroker(id) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return axios.get(
        `${API_URL}/user/get/user/${id}`,
        requestOptions,
    );
}

export function createBroker(params) {

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    let name = params.name;
    let role = params.role;
    let parent = params.parent;
    let address = params.address;
    // let area = params.area;
    let city = params.city;
    let country = params.country;
    let email = params.email;
    let direct_phone = params.direct_phone;
    let state = params.state;
    let zip = params.zip;

    //axios call
    const postData = {
        name,
        role,
        parent,
        email,
        direct_phone,
        address,
        // area,
        city,
        state,
        country,
        zip,
    };
    return axios.post(
        `${API_URL}/auth/broker_signup`,
        postData,
        requestOptions
    );
}

export function updateBroker(params) {

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    let id = params._id;
    let name = params.name;
    let role = params.role;
    let parent = params.parent;
    let address = params.address;
    let city = params.city;
    let country = params.country;
    let email = params.email;
    let direct_phone = params.direct_phone;
    let state = params.state;
    let zip = params.zip;

    //axios call
    const postData = {
        name,
        role,
        parent,
        email,
        direct_phone,
        address,
        // area,
        city,
        state,
        country,
        zip,
    };
    return axios.post(
        `${API_URL}/user/update/user/${id}`,
        postData,
        requestOptions
    );
}

export function deleteBroker(id) {

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return axios.delete(
        `${API_URL}/user/delete/user/${id}`,
        requestOptions
    );
}