import React, { Fragment, useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";

// import styles
import { Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadingToggleAction, updateAgentAction, fetchSingleAgentAction } from '../../../store/actions/AgentActions';
import PageTitle from "../../layouts/PageTitle";
import './agentlist.css';

import {
	getAllOsDataAction,
} from '../../../store/actions/DownloadActions';

import Select from "react-select";

import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';

const CustomClearText = () => "clear all";
const ClearIndicator = (props) => {

	const {
		children = <CustomClearText />,
		getStyles,
		innerProps: { ref, ...restInnerProps },
	} = props;

	return (
		<div
			{...restInnerProps}
			ref={ref}
			style={getStyles("clearIndicator", props)}
		>
			<div style={{ padding: "0px 5px" }}>{children}</div>
		</div>
	);
};

const ClearIndicatorStyles = (base, state) => ({
	...base,
	cursor: "pointer",
	color: state.isFocused ? "blue" : "black",
});

const validateConfig = Yup.object().shape({
	first_name: Yup.string()
		.min(3, "Your first name must consist of at least 3 characters")
		.max(40, "Your first name must consist of maximum 40 characters")
		.required("Please Provide First Name"),
	last_name: Yup.string()
		.min(3, "Your last name must consist of at least 3 characters")
		.max(40, "Your last name must consist of maximum 40 characters")
		.required("Please Provide Last Name"),
	email: Yup.string()
		.min(5, "Your Email must consist of at least 5 characters")
		.max(80, "Your Email must consist of maximum 80 characters")
		.email("Please enter valid email address")
		.required("Please Provide Email"),
	// direct_phone: Yup.string()
	// 	.matches(/^[0-9]{10}$/, 'Phone number is not valid')
	// 	.min(10, "Your phone number must consist of at least 10 characters")
	// 	.max(14, "Your Phone number must consist of maximum 14 characters")
	// 	.required("Please Provide Phone Number"),
	// address: Yup.string()
	// 	.min(10, "Your address must be at least 10 characters long")
	// 	.max(100, "Your address must be at maximum 100 characters long")
	// 	.required("Please Provide Address"),
	// city: Yup.string()
	// 	.min(3, "Your city must be at least 3 characters long")
	// 	.max(50, "Your city must be at maximum 50 characters long")
	// 	.required("Please Provide City"),
	// state: Yup.string()
	// 	.min(3, "Your state must be at least 3 characters long")
	// 	.max(50, "Your state must be at maximum 50 characters long")
	// 	.required("Please Provide State"),
	// country: Yup.string()
	// 	.min(3, "Your country must be at least 3 characters long")
	// 	.max(50, "Your country must be at maximum 50 characters long")
	// 	.required("Please Provide Country"),
	// zip: Yup.string()
	// 	.min(5, "Your zip code must be at least 5 characters long")
	// 	.max(6, "Your zip code must be at maximum 6 characters long")
	// 	.required("Please Provide Zip Code"),
});

const UpdateAgent = ({ singleAgentData, showLoading, allOSOptions }) => {

	let user = JSON.parse(localStorage.getItem('userDetails'))
	let trestleConfig = user.trestleConfig;
	let sparkConfig = user.sparkConfig;
	let mlsgridConfig = user.mlsgridConfig;

	const { id } = useParams();

	const [selectedDateTime, setSelectedDateTime] = useState();

	const [trestleOSSelectedOption, settrestleOSSelectedOption] = useState([]);
	const [trestleOSOptionsList, settrestleOSOptionsList] = useState();

	const [sparkOSSelectedOption, setsparkOSSelectedOption] = useState([]);
	const [sparkOSOptionsList, setsparkOSOptionsList] = useState();

	const [mlsgridOSSelectedOption, setmlsgridOSSelectedOption] = useState([]);
	const [mlsgridOSOptionsList, setmlsgridOSOptionsList] = useState();

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [agents, setAgents] = useState(singleAgentData);


	useEffect(() => {
		dispatch(loadingToggleAction(true));
		dispatch(fetchSingleAgentAction(id));
		setAgents(singleAgentData);

	}, [id, dispatch]);

	useEffect(() => {
		dispatch(getAllOsDataAction());
	}, []);

	useEffect(() => {

		if (trestleConfig && allOSOptions.mls_list_trestle) {
			let newTrestleOsOptions = [];
			allOSOptions.mls_list_trestle.map((val, index) => {
				newTrestleOsOptions.push({ value: val, label: val });
			});
			settrestleOSOptionsList(newTrestleOsOptions);
		}

		if (sparkConfig && allOSOptions.mls_list_spark) {
			let newSparkOsOptions = []
			allOSOptions.mls_list_spark.map((val, index) => {
				newSparkOsOptions.push({ value: val, label: val })
			})
			setsparkOSOptionsList(newSparkOsOptions)
		}

		if (mlsgridConfig && allOSOptions.mls_list_mlsgrid) {
			let newMLSGridOsOptions = []
			allOSOptions.mls_list_mlsgrid.map((val, index) => {
				newMLSGridOsOptions.push({ value: Object.keys(val)[0], label: Object.values(val)[0] })
			})
			setmlsgridOSOptionsList(newMLSGridOsOptions)
		}
	}, [allOSOptions]);

	useEffect(() => {
		setAgents(singleAgentData);
		if (singleAgentData && singleAgentData.date_of_birth) {
			setSelectedDateTime(moment(singleAgentData.date_of_birth).toDate());
		}

		if (trestleOSOptionsList && singleAgentData && singleAgentData.mls_list_trestle) {
			const apiTrestleResponse = singleAgentData.mls_list_trestle;

			const selectedTrestleOptions = trestleOSOptionsList.filter((option) =>
				apiTrestleResponse.includes(option.value)
			);

			settrestleOSSelectedOption(selectedTrestleOptions);
		}

		if (sparkOSOptionsList && singleAgentData && singleAgentData.mls_list_spark) {
			const apiSparkResponse = singleAgentData.mls_list_spark;

			const selectedSparkOptions = sparkOSOptionsList.filter((option) =>
				apiSparkResponse.includes(option.value)
			);

			setsparkOSSelectedOption(selectedSparkOptions);
		}

		if (mlsgridOSOptionsList && singleAgentData && singleAgentData.mls_list_mlsgrid) {
			const apiMLSGridResponse = singleAgentData.mls_list_mlsgrid;

			const selectedMLSGridOptions = mlsgridOSOptionsList.filter((option) =>
				apiMLSGridResponse.includes(option.value)
			);

			setmlsgridOSSelectedOption(selectedMLSGridOptions);
		}
	}, [singleAgentData, allOSOptions]);

	const handleBackButtonClick = () => {
		setAgents({});
		navigate('/agents');
	};

	const handleSubmit = (values, { setSubmitting }) => {

		let trestleOSArray = [];

		if (trestleOSSelectedOption && trestleOSSelectedOption.length > 0) {
			trestleOSArray = Array.from(trestleOSSelectedOption)
				.map(option => option.value)
		}

		values.mls_list_trestle = trestleOSArray;

		let sparkOSArray = [];

		if (sparkOSSelectedOption && sparkOSSelectedOption.length > 0) {
			sparkOSArray = Array.from(sparkOSSelectedOption)
				.map(option => option.value)
		}

		values.mls_list_spark = sparkOSArray;

		let mlsgridOSArray = [];

		if (mlsgridOSSelectedOption && mlsgridOSSelectedOption.length > 0) {
			mlsgridOSArray = Array.from(mlsgridOSSelectedOption)
				.map(option => option.value)
		}

		values.mls_list_mlsgrid = mlsgridOSArray;

		const date_of_birth = moment(values.date_of_birth).format('YYYY-MM-DD');
		values.date_of_birth = date_of_birth;

		dispatch(loadingToggleAction(true));
		dispatch(updateAgentAction(values, navigate));
	};

	return (
		<Fragment>
			<PageTitle activeMenu="Agent" motherMenu="Home" />

			{showLoading && (
				<div className='loader-sec'>
					<div className="loader">Loading...</div>
				</div>
			)}

			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">Update Agent</h4>
							<div className="custom-dropdown mb-0 dropdown">
								<Link to="/agents">
									<button
										type="button"
										className="me-2 btn btn-primary btn-sm btn-rounded"
										onClick={handleBackButtonClick}
									>
										<span className="btn-icon-start text-info">
											<i className="fa fa-arrow-left color-info"></i>
										</span>Back
									</button>
								</Link>
							</div>
						</div>
						<div className="card-body">
							<div className="basic-form">
								<Formik
									initialValues={agents}
									validationSchema={validateConfig}
									onSubmit={handleSubmit}
									enableReinitialize
								>
									{({
										errors,
										values,
										setFieldValue,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										resetForm
									}) => (
										<form onSubmit={handleSubmit}>
											<div
												className={`form-group mb-3 ${values.first_name
													? errors.first_name
														? "is-invalid"
														: "is-valid"
													: ""
													} ${values.middle_name
														? errors.middle_name
															? "is-invalid"
															: "is-valid"
														: ""
													} ${values.last_name
														? errors.last_name
															? "is-invalid"
															: "is-valid"
														: ""
													}`}
											>
												<div className="input-group">
													<span className="input-group-text col-2">
														Full Name
													</span>
													<input
														type="text"
														className="form-control"
														id="val-first_name1"
														autoComplete="off"
														placeholder="Enter First Name"
														name="first_name"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.first_name}
													/>
													<input
														type="text"
														className="form-control"
														id="val-middle_name1"
														autoComplete="off"
														placeholder="Enter Middle Name"
														name="middle_name"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.middle_name}
													/>
													<input
														type="text"
														className="form-control"
														id="val-last_name1"
														autoComplete="off"
														placeholder="Enter Last Name"
														name="last_name"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.last_name}
													/>

													<input
														type="hidden"
														className="form-control"
														id="val-id1"
														autoComplete="off"
														name="id"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values._id}
													/>
												</div>
												<div
													id="val-first_name1-error"
													className="invalid-feedback animated fadeInUp"
													style={{ display: "block" }}
												>
													{errors.first_name && errors.first_name}
												</div>
												<div
													id="val-first_name1-error"
													className="invalid-feedback animated fadeInUp"
													style={{ display: "block" }}
												/>
												<div
													id="val-last_name1-error"
													className="invalid-feedback animated fadeInUp"
													style={{ display: "block" }}
												>
													{errors.last_name && errors.last_name}
												</div>
												<div
													id="val-last_name1-error"
													className="invalid-feedback animated fadeInUp"
													style={{ display: "block" }}
												/>
											</div>
											<div
												className={`form-group mb-3 ${values.email
													? errors.email
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												<div className="input-group">
													<span className="input-group-text col-2">
														Email
													</span>
													<input
														type="email"
														className="form-control"
														id="val-email3"
														autoComplete="off"
														placeholder="Enter Email"
														name="email"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.email}
													/>
													<div
														id="val-email3-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.email && errors.email}
													</div>

													<div
														id="val-email3-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													/>
												</div>
											</div>
											<div
												className={`form-group mb-3`}
											>
												<div className="input-group">
													<span className="input-group-text col-2">
														Contact Number
													</span>
													<input
														type="text"
														className="form-control"
														id="val-direct_phone1"
														autoComplete="off"
														placeholder="Enter Mobile No."
														name="direct_phone"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.direct_phone}
														onKeyDown={(event) => {
															if (!/[0-9]/.test(event.key) && event.key !== "Backspace" && event.key !== "Delete") {
																event.preventDefault();
															}
														}}
													/>
													<input
														type="text"
														className="form-control"
														id="val-cell_phone1"
														autoComplete="off"
														placeholder="Enter Another Mobile No."
														name="cell_phone"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.cell_phone}
													/>
													<input
														type="text"
														className="form-control"
														id="val-office_phone1"
														autoComplete="off"
														placeholder="Enter Office Mobile No."
														name="office_phone"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.office_phone}
														onKeyDown={(event) => {
															if (!/[0-9]/.test(event.key) && event.key !== "Backspace" && event.key !== "Delete") {
																event.preventDefault();
															}
														}}
													/>
												</div>
												{/* <div
													id="val-direct_phone1-error"
													className="invalid-feedback animated fadeInUp"
													style={{ display: "block" }}
												>
													{errors.direct_phone && errors.direct_phone}
												</div> */}
											</div>
											{trestleConfig && (
												<div
													className={`form-group mb-3`}
												>
													<div className="input-group">
														<span className="input-group-text col-2">
															Trestle Operating System
														</span>
														<Select
															className="form-control"
															closeMenuOnSelect={true}
															components={{ ClearIndicator }}
															styles={{ clearIndicator: ClearIndicatorStyles }}
															value={trestleOSSelectedOption}
															name="mls_list_trestle"
															isMulti
															options={trestleOSOptionsList}
															onChange={(selectedOption) => {
																settrestleOSSelectedOption(selectedOption);
															}
															}
														/>
													</div>
												</div>
											)}
											{sparkConfig && (
												<div
													className={`form-group mb-3`}
												>
													<div className="input-group">
														<span className="input-group-text col-2">
															Spark Operating System
														</span>
														<Select
															className="form-control"
															closeMenuOnSelect={true}
															components={{ ClearIndicator }}
															styles={{ clearIndicator: ClearIndicatorStyles }}
															value={sparkOSSelectedOption}
															name="mls_list_spark"
															isMulti
															options={sparkOSOptionsList}
															onChange={(selectedOption) => {
																setsparkOSSelectedOption(selectedOption);
															}
															}
														/>
													</div>
												</div>
											)}
											{mlsgridConfig && (
												<div
													className={`form-group mb-3`}
												>
													<div className="input-group">
														<span className="input-group-text col-2">
															MLSGrid Operating System
														</span>
														<Select
															className="form-control"
															closeMenuOnSelect={true}
															components={{ ClearIndicator }}
															styles={{ clearIndicator: ClearIndicatorStyles }}
															value={mlsgridOSSelectedOption}
															name="mls_list_mlsgrid"
															isMulti
															options={mlsgridOSOptionsList}
															onChange={(selectedOption) => {
																setmlsgridOSSelectedOption(selectedOption);
															}
															}
														/>
													</div>
												</div>
											)}
											<div
												className={`form-group mb-3 ${values.office
													? errors.office
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												<div className="input-group">
													<span className="input-group-text col-2">
														Office Address
													</span>
													<input
														type="text"
														className="form-control"
														id="val-office1"
														autoComplete="off"
														placeholder="Enter Office Address"
														name="office"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.office}
													/>
													<div
														id="val-office1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.office && errors.office}
													</div>
													<div
														id="val-office1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													/>
												</div>
											</div>
											<div className="form-group mb-3">
												<div className="input-group">
													<span className="input-group-text col-2">
														BirthDate
													</span>
													<DateTime
														name="date_of_birth"
														value={selectedDateTime}
														onChange={date => {
															if (date) {
																setSelectedDateTime(date);
															} else {
																setSelectedDateTime(moment());
															}
															setFieldValue('date_of_birth', date);
														}}
														maxDate={moment()}
														inputProps={{
															name: 'date_of_birth',
															id: 'date_of_birth'
														}}
														dateFormat="DD-MM-YYYY"
														timeFormat=""
														isValidDate={date => moment(date).isSameOrBefore(moment().subtract(18, 'years'))}
														renderInput={(props, openCalendar, closeCalendar) => (
															<input
																{...props}
																onClick={openCalendar}
																value={selectedDateTime ? moment(selectedDateTime).format('DD-MM-YYYY') : ''}
															/>
														)}
													/>

												</div>
											</div>
											<div
												className={`form-group mb-3 ${values.dre
													? errors.dre
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														DRE
													</span>

													<input
														type="text"
														className="form-control"
														id="val-dre1"
														autoComplete="off"
														name="dre"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.dre}
														placeholder="Enter DRE"
													/>
													<div
														id="val-dre1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.dre && errors.dre}
													</div>
												</div>
											</div>
											<div
												className={`form-group mb-3 ${values.nred
													? errors.nred
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														NRED
													</span>

													<input
														type="text"
														className="form-control"
														id="val-nred1"
														autoComplete="off"
														name="nred"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.nred}
														placeholder="Enter NRED"
													/>
													<div
														id="val-nred1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.nred && errors.nred}
													</div>
												</div>
											</div>
											<button
												type="submit"
												className="btn me-2 btn-primary"
												disabled={showLoading}
											>
												Submit
											</button>
											<button
												type="button"
												className="btn btn-danger light"
												onClick={() => resetForm()}
											>
												Cancel
											</button>
										</form>
									)}
								</Formik>

								<ToastContainer />
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment >
	);
};

const mapStateToProps = (state) => {
	return {
		errorMessage: state.agents.errorMessage,
		successMessage: state.agents.successMessage,
		showLoading: state.agents.showLoading,
		singleAgentData: state.agents.singleAgentData,
		allOSOptions: state.groupby.allOSOptions,
	};
};
export default connect(mapStateToProps)(UpdateAgent);
