import axios from 'axios';
import { API_URL } from '../apiConfig';

export function authHeader() {
    let user = JSON.parse(localStorage.getItem('userDetails'))

    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token }
    } else {
        return {}
    }
}

export function fetchProperty(page, limit, sortData, filterValues) {

    const sort = {
        [`Data.${sortData.value}`]: 'desc'
    };
    let postData = {
        sort,
        ...filterValues
    };

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return axios.post(
        `${API_URL}/spark/get/properties?page=${page}&limit=${limit}`,
        postData,
        requestOptions,
    );
}

export function fetchSingleProperty(propertyType, id) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return axios.get(
        `${API_URL}/${propertyType}/get/property/${id}`,
        requestOptions,
    );
}

export function fetchImportProperty(page, limit, filterValues) {

    const propertyType = filterValues.PropertyType;

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return axios.get(
        `${API_URL}/` + propertyType + `/get/importList?page=${page}&limit=${limit}`,
        requestOptions,
    );
}

export function fetchChartProperty(filterValues) {

    const propertyType = filterValues.PropertyType;

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return axios.get(
        `${API_URL}/` + propertyType + `/get/groupBy`,
        requestOptions,
    );
}