import React, { Fragment, useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
// import styles
import { Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { loadingToggleAction, updateAgentProfileAction } from '../../../../store/actions/AuthActions';

import PageTitle from "../../../layouts/PageTitle";

const validateConfig = Yup.object().shape({
	first_name: Yup.string()
		.min(5, "Your first name must consist of at least 5 characters ")
		.max(40, "Your first name must consist of maximum 40 characters ")
		.required("Please Provide First Name"),
	last_name: Yup.string()
		.min(5, "Your last name must consist of at least 5 characters ")
		.max(40, "Your last name must consist of maximum 40 characters ")
		.required("Please Provide Last Name"),
	office: Yup.string()
		.min(5, "Your office must be at least 5 characters long")
		.max(100, "Your office must be at maximum 100 characters long")
		.required("Please Provide Office Address"),
	// direct_phone: Yup.string()
	// 	.matches(/^[0-9]{10}$/, 'Phone number is not valid')
	// 	.min(10, "Your phone number must consist of at least 10 characters")
	// 	.max(10, "Your Phone number must consist of maximum 10 characters"),

});

const ViewProfile = ({ showLoading }) => {

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const userData = JSON.parse(localStorage.getItem('userDetails'));

	const [values, setValues] = useState({
		first_name: userData.first_name || '',
		middle_name: userData.middle_name || '',
		last_name: userData.last_name || '',
		office: userData.office || '',
		direct_phone: userData.direct_phone || '',
		cell_phone: userData.cell_phone || '',
		office_phone: userData.office_phone || '',
		dre: userData.dre || '',
		nred: userData.nred || '',
	});

	const handleSubmit = (values, { setSubmitting }) => {
		dispatch(loadingToggleAction(true));
		dispatch(updateAgentProfileAction(values.first_name, values.middle_name, values.last_name, values.office, values.direct_phone, values.cell_phone, values.office_phone, values.dre, values.nred, navigate));

		setSubmitting(false);
	};

	return (
		<Fragment>
			<PageTitle activeMenu="Profile" motherMenu="Home" />

			{showLoading && (
				<div className='loader-sec'>
					<div className="loader">Loading...</div>
				</div>
			)}

			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">Manage Profile</h4>
						</div>
						<div className="card-body">
							<div className="basic-form">
								<Formik
									initialValues={values}
									validationSchema={validateConfig}
									onSubmit={handleSubmit}
								>
									{({
										values,
										errors,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										resetForm
									}) => (
										<form onSubmit={handleSubmit}>
											<div
												className={`form-group mb-3 ${values.first_name
													? errors.first_name
														? "is-invalid"
														: "is-valid"
													: ""
													} ${values.middle_name
														? errors.middle_name
															? "is-invalid"
															: "is-valid"
														: ""
													} ${values.last_name
														? errors.last_name
															? "is-invalid"
															: "is-valid"
														: ""
													}`}
											>
												<div className="input-group">
													<span className="input-group-text col-2">
														Full Name
													</span>
													<input
														type="text"
														className="form-control"
														id="val-first_name1"
														autoComplete="off"
														placeholder="Enter First Name"
														name="first_name"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.first_name}
													/>
													<input
														type="text"
														className="form-control"
														id="val-middle_name1"
														autoComplete="off"
														placeholder="Enter Middle Name"
														name="middle_name"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.middle_name}
													/>
													<input
														type="text"
														className="form-control"
														id="val-last_name1"
														autoComplete="off"
														placeholder="Enter Last Name"
														name="last_name"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.last_name}
													/>
												</div>
												<div
													id="val-first_name1-error"
													className="invalid-feedback animated fadeInUp"
													style={{ display: "block" }}
												>
													{errors.first_name && errors.first_name}
												</div>
												<div
													id="val-first_name1-error"
													className="invalid-feedback animated fadeInUp"
													style={{ display: "block" }}
												/>
												<div
													id="val-last_name1-error"
													className="invalid-feedback animated fadeInUp"
													style={{ display: "block" }}
												>
													{errors.last_name && errors.last_name}
												</div>
												<div
													id="val-last_name1-error"
													className="invalid-feedback animated fadeInUp"
													style={{ display: "block" }}
												/>
											</div>
											<div
												className={`form-group mb-3 ${values.office
													? errors.office
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														Office
													</span>

													<input
														type="text"
														className="form-control"
														id="val-office1"
														autoComplete="off"
														name="office"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.office}
														placeholder="Enter Office Address"
													/>
													<div
														id="val-office1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.office && errors.office}
													</div>
												</div>
											</div>
											<div
												className={`form-group mb-3`}
											>
												<div className="input-group">
													<span className="input-group-text col-2">
														Contact Number
													</span>
													<input
														type="text"
														className="form-control"
														id="val-direct_phone1"
														autoComplete="off"
														placeholder="Enter Mobile No."
														name="direct_phone"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.direct_phone}
													/>
													<input
														type="text"
														className="form-control"
														id="val-cell_phone1"
														autoComplete="off"
														placeholder="Enter Another Mobile No."
														name="cell_phone"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.cell_phone}
													/>
													<input
														type="text"
														className="form-control"
														id="val-office_phone1"
														autoComplete="off"
														placeholder="Enter Office Mobile No."
														name="office_phone"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.office_phone}
													/>
												</div>
											</div>
											<div
												className={`form-group mb-3 ${values.dre
													? errors.dre
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														DRE
													</span>

													<input
														type="text"
														className="form-control"
														id="val-dre1"
														autoComplete="off"
														name="dre"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.dre}
														placeholder="Enter DRE"
													/>
													<div
														id="val-name1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.dre && errors.dre}
													</div>
												</div>
											</div>
											<div
												className={`form-group mb-3 ${values.nred
													? errors.nred
														? "is-invalid"
														: "is-valid"
													: ""
													}`}
											>
												<div className="input-group transparent-append mb-2">

													<span className="input-group-text col-2">
														NRED
													</span>

													<input
														type="text"
														className="form-control"
														id="val-nred1"
														autoComplete="off"
														name="nred"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.nred}
														placeholder="Enter NRED"
													/>
													<div
														id="val-name1-error"
														className="invalid-feedback animated fadeInUp"
														style={{ display: "block" }}
													>
														{errors.nred && errors.nred}
													</div>
												</div>
											</div>
											<button
												type="submit"
												className="btn me-2 btn-primary"
												disabled={isSubmitting}
											>
												Submit
											</button>
											<button
												type="button"
												className="btn btn-danger light"
												onClick={() => resetForm()}
											>
												Cancel
											</button>
										</form>
									)}
								</Formik>

								<ToastContainer />
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		errorMessage: state.auth.errorMessage,
		successMessage: state.auth.successMessage,
		showLoading: state.auth.showLoading,
	};
};
export default connect(mapStateToProps)(ViewProfile);
