import {
    TRESTLE_CONFIRMED_ACTION,
    TRESTLE_FAILED_ACTION,
    SPARK_CONFIRMED_ACTION,
    SPARK_FAILED_ACTION,
    MLSGRID_CONFIRMED_ACTION,
    MLSGRID_FAILED_ACTION,
    CONFIG_LOADING_TOGGLE_ACTION,
} from '../actions/ConfigActions';

const initialState = {
    auth: {
        email: '',
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function ConfigReducer(state = initialState, action) {
    if (action.type === TRESTLE_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Configuration Successfully Completed',
            showLoading: false,
        };
    }
    if (action.type === TRESTLE_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }
    if (action.type === SPARK_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Configuration Successfully Completed',
            showLoading: false,
        };
    }
    if (action.type === SPARK_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }
    if (action.type === MLSGRID_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Configuration Successfully Completed',
            showLoading: false,
        };
    }
    if (action.type === MLSGRID_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }
    if (action.type === CONFIG_LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}


