import { showToast } from '../../services/AuthService';
import { getGroupByData, getAllOsData, downloadData } from '../../services/DownloadService';
import swal from 'sweetalert';

export function getGroupByDataAction(groupbyName, propertyType) {
    return (dispatch) => {
        getGroupByData(groupbyName, propertyType)
            .then((response) => {
                if (groupbyName === 'OriginatingSystemName')
                    dispatch(fetchOsOptionsSuccess(response.data.result));
                else
                    dispatch(fetchStatusOptionsSuccess(response.data.result));
            })
            .catch((error) => {
                if (groupbyName === 'OriginatingSystemName')
                    dispatch(fetchOsOptionsFailure(error.response.data));
                else
                    dispatch(fetchStatusOptionsFailure(error.response.data));
            });
    };
}

export function getAllOsDataAction() {
    return (dispatch) => {
        getAllOsData()
            .then((response) => {
                dispatch(fetchAllOsOptionsSuccess(response.data.result));
            })
            .catch((error) => {
                dispatch(fetchAllOsOptionsFailure(error.response.data));
            });
    };
}

export function downloadDataAction(PropertyType, MlsStatus, OperatingSystem, MinListPrice, MaxListPrice, ModificationTimestamp, ImportStart) {
    return (dispatch) => {
        downloadData(PropertyType, MlsStatus, OperatingSystem, MinListPrice, MaxListPrice, ModificationTimestamp, ImportStart)
            .then((response) => {

                swal({
                    title: "Total Data Count: " + response.data.result.dataCount,
                    text:
                        response.data.message,
                    icon: "info",
                    buttons: true,
                    dangerMode: true,
                }).then((willDelete) => {
                    if (willDelete) {
                        ImportStart = true;

                        downloadData(PropertyType, MlsStatus, OperatingSystem, MinListPrice, MaxListPrice, ModificationTimestamp, ImportStart)
                            .then((response) => {

                                const successMessage = showToast(response.data);
                                dispatch(downloadSuccessAction(response.data));

                                const existingData = JSON.parse(localStorage.getItem("userDetails")) || {};
                                const filtersData = {
                                    'filters': {
                                        'PropertyType': {},
                                        'MlsStatus': [],
                                        'OperatingSystem': [],
                                        'MinListPrice': {},
                                        'MaxListPrice': {},
                                        'ModificationTimestamp': {},
                                    }
                                }
                                filtersData.filters.PropertyType = PropertyType;
                                filtersData.filters.MlsStatus = MlsStatus;
                                filtersData.filters.OperatingSystem = OperatingSystem;
                                filtersData.filters.MinListPrice = MinListPrice;
                                filtersData.filters.MaxListPrice = MaxListPrice;
                                filtersData.filters.ModificationTimestamp = ModificationTimestamp;
                                const updatedData = { ...existingData, ...filtersData }

                                localStorage.setItem('userDetails', JSON.stringify(updatedData));
                            })
                            .catch((error) => {
                                const errorMessage = showToast(error.response.data);
                                dispatch(downloadFailedAction(error.response.data));
                            });
                    } else {
                        dispatch(loadingToggleAction(false));
                    }
                })
            })
            .catch((error) => {
                const errorMessage = showToast(error.response.data);
                dispatch(downloadFailedAction(error.response.data));
            });
    };
}

export function downloadConfirmAction(data) {
    return {
        type: 'DOWNLOAD_CONFIRM_ACTION',
        payload: data,
    };
}

export function downloadSuccessAction(data) {
    return {
        type: 'DOWNLOAD_CONFIRMED_ACTION',
        payload: data,
    };
}

export function downloadFailedAction(data) {
    return {
        type: 'DOWNLOAD_FAILED_ACTION',
        payload: data,
    };
}

export const fetchOsOptionsSuccess = options => {
    return {
        type: 'FETCH_OS_OPTIONS_SUCCESS',
        payload: options
    };
};

export const fetchOsOptionsFailure = error => {
    return {
        type: 'FETCH_OS_OPTIONS_FAILURE',
        payload: error
    };
};

export const fetchAllOsOptionsSuccess = options => {
    return {
        type: 'FETCH_ALL_OS_OPTIONS_SUCCESS',
        payload: options
    };
};

export const fetchAllOsOptionsFailure = error => {
    return {
        type: 'FETCH_ALL_OS_OPTIONS_FAILURE',
        payload: error
    };
};

export const fetchStatusOptionsSuccess = options => {
    return {
        type: 'FETCH_STATUS_OPTIONS_SUCCESS',
        payload: options
    };
};

export const fetchStatusOptionsFailure = error => {
    return {
        type: 'FETCH_STATUS_OPTIONS_FAILURE',
        payload: error
    };
};

export function loadingToggleAction(status) {
    return {
        type: 'DOWNLOAD_LOADING_TOGGLE_ACTION',
        payload: status,
    };
}

