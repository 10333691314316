import { showToast } from '../../services/AuthService';
import { trestleConfig, sparkConfig, mlsgridConfig } from '../../services/ConfigService';

export const TRESTLE_CONFIRMED_ACTION = '[confirm action] trestle confirmed';
export const TRESTLE_FAILED_ACTION = '[failed action] trestle failed';
export const SPARK_CONFIRMED_ACTION = '[confirm action] spark confirmed';
export const SPARK_FAILED_ACTION = '[confirm action] spark confirmed';
export const MLSGRID_CONFIRMED_ACTION = '[confirm action] trestle confirmed';
export const MLSGRID_FAILED_ACTION = '[failed action] trestle failed';
export const CONFIG_LOADING_TOGGLE_ACTION = '[Config Loading action] toggle loading';

export function trestleConfigAction(clientid, clientsecret, navigate) {
    return (dispatch) => {
        trestleConfig(clientid, clientsecret)
            .then((response) => {
                const successMessage = showToast(response.data);
                dispatch(trestleConfirmedAction(response.data));

                let user = JSON.parse(localStorage.getItem('userDetails'))
                user.trestleConfig = true;
                localStorage.setItem('userDetails', JSON.stringify(user));
            })
            .catch((error) => {
                const errorMessage = showToast(error.response.data);
                dispatch(trestleFailedAction(error.response.data));
                // let user = JSON.parse(localStorage.getItem('userDetails'))
                // user.trestleConfig = false;
                // localStorage.setItem('userDetails', JSON.stringify(user));
            });
    };
}

export function trestleConfirmedAction(data) {
    return {
        type: TRESTLE_CONFIRMED_ACTION,
        payload: data,
    };
}

export function trestleFailedAction(data) {
    return {
        type: TRESTLE_FAILED_ACTION,
        payload: data,
    };
}

export function loadingToggleAction(status) {
    return {
        type: CONFIG_LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

export function sparkConfigAction(accesstoken, navigate) {
    return (dispatch) => {
        sparkConfig(accesstoken)
            .then((response) => {
                const successMessage = showToast(response.data);
                dispatch(sparkConfirmedAction(response.data));

                let user = JSON.parse(localStorage.getItem('userDetails'))
                user.sparkConfig = true;
                localStorage.setItem('userDetails', JSON.stringify(user));
            })
            .catch((error) => {
                const errorMessage = showToast(error.response.data);
                dispatch(sparkFailedAction(error.response.data));
                // let user = JSON.parse(localStorage.getItem('userDetails'))
                // user.sparkConfig = false;
                // localStorage.setItem('userDetails', JSON.stringify(user));
            });
    };
}

export function sparkConfirmedAction(data) {
    return {
        type: SPARK_CONFIRMED_ACTION,
        payload: data,
    };
}

export function sparkFailedAction(data) {
    return {
        type: SPARK_FAILED_ACTION,
        payload: data,
    };
}

export function mlsgridConfigAction(accesstoken, refreshtoken, navigate) {
    return (dispatch) => {
        mlsgridConfig(accesstoken, refreshtoken)
            .then((response) => {
                const successMessage = showToast(response.data);
                dispatch(mlsgridConfirmedAction(response.data));

                let user = JSON.parse(localStorage.getItem('userDetails'))
                user.mlsgridConfig = true;
                localStorage.setItem('userDetails', JSON.stringify(user));
            })
            .catch((error) => {
                const errorMessage = showToast(error.response.data);
                dispatch(mlsgridFailedAction(error.response.data));
                // let user = JSON.parse(localStorage.getItem('userDetails'))
                // user.mlsgridConfig = false;
                // localStorage.setItem('userDetails', JSON.stringify(user));
            });
    };
}

export function mlsgridConfirmedAction(data) {
    return {
        type: MLSGRID_CONFIRMED_ACTION,
        payload: data,
    };
}

export function mlsgridFailedAction(data) {
    return {
        type: MLSGRID_FAILED_ACTION,
        payload: data,
    };
}