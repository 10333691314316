import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Nav, Tab } from "react-bootstrap";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import style from './PropertyDetails.css';

import PageTitle from "../../layouts/PageTitle";
import {
	loadingToggleAction,
	fetchSinglePropertyAction,
} from '../../../store/actions/PropertyActions';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
	const { onClick } = props;
	return (
		<div className="owl-nav">
			<div className="owl-next fas fa-chevron-right" onClick={onClick} />
		</div>
	);
}

function SamplePrevArrow(props) {
	const { onClick } = props;
	return (
		<div className="owl-nav">
			<div className=" owl-prev fas fa-chevron-left" onClick={onClick} style={{ zIndex: 1 }} />
		</div>
	);
}

const PropertyDetails = ({ singlePropertyData, showLoading }) => {

	const settings = {
		dots: false,
		infinite: true,
		arrows: true,
		speed: 2500,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1600,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	let user = JSON.parse(localStorage.getItem('userDetails'))
	let role = user.authorities.name;

	// console.log('singlePropertyData', singlePropertyData)

	const isFirstRender = useRef(true);
	const { propertyType, id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [items, setItems] = useState(singlePropertyData);

	useEffect(() => {

		if (role != 'superadmin') {
			dispatch(loadingToggleAction(true));
			dispatch(fetchSinglePropertyAction(propertyType, id));
			setItems(singlePropertyData);
		}

	}, [id, dispatch]);

	function handleClick(e) {
		e.preventDefault();
	}

	return (
		<>
			<PageTitle
				activeMenu="Property Details"
				motherMenu="Home"
				pageContent="Property Details"
			/>

			{showLoading && (
				<div className='loader-sec'>
					<div className="loader">Loading...</div>
				</div>
			)}

			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">Property Details</h4>
							<div className="custom-dropdown mb-0 dropdown">
								<Link to={`/${propertyType}-data`}>
									<button type="button" className="me-2 btn btn-primary btn-sm btn-rounded">
										<span className="btn-icon-start text-info">
											<i className="fa fa-arrow-left color-info"></i>
										</span>Back
									</button>
								</Link>
							</div>
						</div>

						<div className="card-body pb-3">
							<div className="items position-relative">
								<div className="front-view">
									{singlePropertyData && singlePropertyData.Data && singlePropertyData.Data[0].Media && singlePropertyData.Data[0].Media.length > 0 && (
										<img src={singlePropertyData.Data[0].Media[0].MediaURL} alt="" className="w-100" style={{ height: "400px", borderRadius: "19px" }} />
									)}
									<div className='backdrop'></div>
									<div className="sale">
										<span>{singlePropertyData && singlePropertyData.Data && singlePropertyData.Data[0].StandardStatus && (singlePropertyData.Data[0].StandardStatus)}</span>
									</div>
									<div className="info">
										<h3 className="mb-3 text-white font-w600">
											{singlePropertyData.ListingType && (singlePropertyData.ListingType.toUpperCase())}
										</h3>
										<h5 className="mb-3 text-white font-w600">
											{singlePropertyData.OriginatingSystemName && (singlePropertyData.OriginatingSystemName.toUpperCase())}
										</h5>
									</div>
								</div>
							</div>
							<div className="card-body p-sm-3 p-0">
								<div className="row">
									<div className="col-xl-3 col-xxl-4">
										<div className="my-profile mb-5">
											<div className="avtar mb-3 text-center">
												{/* <img src={pic1} alt="" /> */}
												<div className="mb-5">

													<div className="bg-primary text-center price mb-4">
														<div className="card-body">
															{singlePropertyData && singlePropertyData.Data && singlePropertyData.Data[0].YearBuilt && (
																<h4 className="font-w600 mb-3 text-white Price">
																	Built Year: {singlePropertyData.Data[0].YearBuilt}
																</h4>
															)}
															{singlePropertyData && singlePropertyData.Data && singlePropertyData.Data[0].ListPrice && (
																<h2 className="fs-30 text-white mb-0 font-w600">
																	Price: ${singlePropertyData.Data[0].ListPrice}
																</h2>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="col-xl-12 col-xxl-12">
										<div className="mb-5">
											{/* <div className="text-center">
												<h4 className="text-black fs-20 font-w600">
													{singlePropertyData.ListingType && (singlePropertyData.ListingType.toUpperCase())}
												</h4>
												<span className="mb-3 d-block text-success fs-16">
													{singlePropertyData.OriginatingSystemName && (singlePropertyData.OriginatingSystemName.toUpperCase())}
												</span>
											</div> */}
											<h4 className="text-black fs-30 font-w600 mb-3">Description</h4>
											<p className="fs-16 text-justify">
												{singlePropertyData && singlePropertyData.Data && singlePropertyData.Data[0].StandardStatus && (singlePropertyData.Data[0].PublicRemarks)}
											</p>
										</div>
										<div className="col-md-12 col-xxl-12">
											<div className="card">
												<div className='card details-card '>
													<div className='card-header'>
														Listing Id : &nbsp;
														<span className="item">
															<b>
																{singlePropertyData && singlePropertyData.Data && singlePropertyData.Data[0].ListingId ? (
																	<li>{singlePropertyData.Data[0].ListingId}</li>
																) : (
																	<li>N/A</li>
																)}
															</b>
														</span>
													</div>
													<div className='card-header'>
														Property Type : &nbsp;
														<span className="item">
															<b>
																{singlePropertyData && singlePropertyData.Data && singlePropertyData.Data[0].PropertyType ? (
																	<li>{singlePropertyData.Data[0].PropertyType}</li>
																) : (
																	<li>N/A</li>
																)}
															</b>
														</span>
													</div>
													<div className='card-header'>
														Property Sub Type: &nbsp;
														<span className="item">
															<b>
																{singlePropertyData && singlePropertyData.Data && singlePropertyData.Data[0].PropertySubType ? (
																	<li>{singlePropertyData.Data[0].PropertySubType}</li>
																) : (
																	<li>N/A</li>
																)}
															</b>
														</span>
													</div>
													<div className='card-header'>
														Possible Use : &nbsp;
														<span className="item">
															<b>
																{singlePropertyData && singlePropertyData.Data && singlePropertyData.Data[0].PossibleUse ? (
																	<li>{singlePropertyData.Data[0].PossibleUse}</li>
																) : (
																	<li>N/A</li>
																)}
															</b>
														</span>
													</div>
													<div className='card-header'>
														Current Use: &nbsp;
														<span className="item">
															<b>
																{singlePropertyData && singlePropertyData.Data && singlePropertyData.Data[0].CurrentUse ? (
																	<li>{singlePropertyData.Data[0].CurrentUse}</li>
																) : (
																	<li>N/A</li>
																)}
															</b>
														</span>
													</div>
													<div className='card-header'>
														Modification Time : &nbsp;
														<span className="item">
															<b>
																{singlePropertyData && singlePropertyData.Data && singlePropertyData.Data[0].ModificationTimestamp ? (
																	<li>{new Date(singlePropertyData.ModificationTimestamp).toLocaleString()}</li>
																) : (
																	<li>N/A</li>
																)}
															</b>
														</span>
													</div>
												</div>
												<div className='card-footer'>
													Directions: &nbsp;
													<span className="item">
														<b>
															{singlePropertyData && singlePropertyData.Data && singlePropertyData.Data[0].Directions ? (
																<li>{singlePropertyData.Data[0].Directions}</li>
															) : (
																<li>N/A</li>
															)}
														</b>
													</span>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-12'>
										<div className="mb-5">
											<h4 className="fs-30 font-w600 mb-3">Gallery</h4>
											<Slider className="image-gallery owl-carousel" {...settings}>
												{singlePropertyData && singlePropertyData.Data && singlePropertyData.Data[0].Media && singlePropertyData.Data[0].Media.length > 0 && (
													singlePropertyData.Data[0].Media.map((item, index) => (
														<div className="items px-3" key={index}>
															<img src={item.MediaURL} alt="" className="w-100" />
														</div>
													))
												)}
											</Slider>
										</div>
									</div>

									<div className='col-lg-12'>
										<h4 className="fs-30 font-w600 my-3">Features</h4>
										<ul className="property-features">

											{(() => {
												const LaundryFeaturesAvail = singlePropertyData && singlePropertyData.Data && singlePropertyData.Data[0].LaundryFeatures;

												const feature_icon = LaundryFeaturesAvail ? 'fa-check-circle text-success' : 'fa-times-circle text-danger';

												const feature_text = 'Laundry';

												return <li><i title={LaundryFeaturesAvail} className={`fas ${feature_icon}`}></i>
													{feature_text}
												</li>;
											})()}

											{(() => {
												const SecurityFeaturesAvail = singlePropertyData && singlePropertyData.Data && singlePropertyData.Data[0].SecurityFeatures;

												const feature_icon = SecurityFeaturesAvail ? 'fa-check-circle text-success' : 'fa-times-circle text-danger';

												const feature_text = 'Security';

												return <li><i title={SecurityFeaturesAvail} className={`fas ${feature_icon}`}></i>
													{feature_text}
												</li>;
											})()}

											{(() => {
												const ParkingFeaturesAvail = singlePropertyData && singlePropertyData.Data && singlePropertyData.Data[0].ParkingFeatures;

												const feature_icon = ParkingFeaturesAvail ? 'fa-check-circle text-success' : 'fa-times-circle text-danger';

												const feature_text = 'Parking';

												return <li><i title={ParkingFeaturesAvail} className={`fas ${feature_icon}`}></i>
													{feature_text}
												</li>;
											})()}

											{(() => {
												const PoolFeaturesAvail = singlePropertyData && singlePropertyData.Data && singlePropertyData.Data[0].PoolFeatures;

												const feature_icon = PoolFeaturesAvail ? 'fa-check-circle text-success' : 'fa-times-circle text-danger';

												const feature_text = 'Pool';

												return <li><i title={PoolFeaturesAvail} className={`fas ${feature_icon}`}></i>
													{feature_text}
												</li>;
											})()}

											{(() => {
												const CommunityFeaturesAvail = singlePropertyData && singlePropertyData.Data && singlePropertyData.Data[0].LaundryCommunity;

												const feature_icon = CommunityFeaturesAvail ? 'fa-check-circle text-success' : 'fa-times-circle text-danger';

												const feature_text = 'Community';

												return <li><i title={CommunityFeaturesAvail} className={`fas ${feature_icon}`}></i>
													{feature_text}
												</li>;
											})()}

											{(() => {
												const SpaFeaturesAvail = singlePropertyData && singlePropertyData.Data && singlePropertyData.Data[0].SpaFeatures;

												const feature_icon = SpaFeaturesAvail ? 'fa-check-circle text-success' : 'fa-times-circle text-danger';

												const feature_text = 'Spa';

												return <li><i title={SpaFeaturesAvail} className={`fas ${feature_icon}`}></i>
													{feature_text}
												</li>;
											})()}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div >
				</div >
			</div >
		</>
	);
};

const mapStateToProps = (state) => {
	// console.log('aaa', state.property.showLoading)
	return {
		errorMessage: state.property.errorMessage,
		successMessage: state.property.successMessage,
		showLoading: state.property.showLoading,
		singlePropertyData: state.property.singlePropertyData,
	};
};
export default connect(mapStateToProps)(PropertyDetails);
