import React, { Fragment, useState, useEffect, useRef, useMemo } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";

import { useTable, useGlobalFilter, useFilters, usePagination, } from 'react-table';
import MOCK_DATA from '../PropertyListing/MOCK_DATA_2.json';
import { COLUMNS } from '../PropertyListing/Columns';
import { GlobalFilter } from '../PropertyListing/GlobalFilter';
//import './table.css';
import './userlist.css';
import {
	Row,
	Col,
	Card,
	Table,
	Badge,
	Dropdown,
	ProgressBar,
} from "react-bootstrap";

import {
	getUsersDataAction,
	loadingToggleAction,
} from '../../../store/actions/UserActions';

export const UserListing = (userData) => {
	const columns = useMemo(() => COLUMNS, [])
	const data = useMemo(() => MOCK_DATA, [])
	const users = useSelector(state => state.users.userData.docs);

	const tableInstance = useTable({
		columns,
		data,
		initialState: { pageIndex: 0 }
	}, useFilters, useGlobalFilter, usePagination)

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(getUsersDataAction(navigate));
	}, [dispatch]);

	return (
		<>
			<PageTitle
				activeMenu="Users"
				motherMenu="Form"
				pageContent="Users"
			/>
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Users</Card.Title>
							<div className="custom-dropdown mb-0 dropdown">
								<Link to="/users/update-user">
									<button type="button" className="me-2 btn btn-primary btn-sm btn-rounded">
										<span className="btn-icon-start text-info">
											<i className="fa fa-plus color-info"></i>
										</span>Add
									</button>
								</Link>
							</div>
						</Card.Header>
						<Card.Body>
							<Table responsive>
								<thead>
									<tr>
										<th>
											<strong>NAME</strong>
										</th>
										<th>
											<strong>Email</strong>
										</th>
										{/* <th>
											<strong>Action</strong>
										</th> */}
									</tr>
								</thead>
								<tbody>
									{!users && (
										<tr>
											<td colSpan="3" className="text-center">No record found.</td>
										</tr>
									)}
									{users && (users.map((user, index) => (
										<tr key={index}>
											<td>
												<div className="d-flex align-items-center">
													{" "}
													<span className="w-space-no">{user.name}</span>
												</div>
											</td>
											<td>{user.email}</td>
											{/* <td>
												<div className="d-flex">
													<Link
														to={`/users/update-user/${user._id}`}
														className="btn btn-primary shadow btn-xs sharp me-1"
													>
														<i className="fas fa-pen"></i>
													</Link>
													<Link
														href="#"
														className="btn btn-danger shadow btn-xs sharp"
													>
														<i className="fa fa-trash"></i>
													</Link>
												</div>
											</td> */}
										</tr>
									)))}
								</tbody>
							</Table>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

const mapStateToProps = (state) => {

	return {
		errorMessage: state.users.errorMessage,
		successMessage: state.users.successMessage,
		showLoading: state.users.showLoading,
		userData: state.users.userData,
	};
};
export default connect(mapStateToProps)(UserListing);