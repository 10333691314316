import React, { useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  loadingToggleAction,
  loginAction,
  sendOtpAction,
  resetStageAction,
} from '../../store/actions/AuthActions';
// image
//import logo from "../../images/logo-text.png";
//import loginbg from "../../images/login-bg-1.jpg";
import loginbg from "../../images/login-bg-4.jpg";
//import loginbg from "../../images/login-bg-1.jpg";

function Login(props) {
  var d = new Date();
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  let errorsObj = { email: '' };
  const [errors, setErrors] = useState(errorsObj);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function sendOtp(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === '') {
      errorObj.email = 'Email is Required';
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(loadingToggleAction(true));
    dispatch(sendOtpAction(email, navigate));
  }

  function checkLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === '') {
      errorObj.email = 'Email is Required';
      error = true;
    }
    if (otp === '') {
      errorObj.otp = 'OTP is Required';
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(loadingToggleAction(true));
    dispatch(loginAction(email, otp, navigate));
  }

  function handleClick() {
    dispatch(resetStageAction(navigate));
  }

  return (
    <div className="login-main-page" style={{ backgroundImage: "url(" + loginbg + ")" }}>
      <div className="login-wrapper">
        <div className="login-aside-left" >
          <Link to="/dashboard" className="login-logo">

            <h2 className="main-title mb-2"><svg className="logo-abbr me-3" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect className="rect-primary-rect" width="64" height="64" rx="18" fill="#F2F6FC" />
              <path d="M33.9126 48.6459H16.7709C15.9917 48.6459 15.3542 48.0084 15.3542 47.2292V22.9334C15.3542 22.1542 15.9917 21.5167 16.7709 21.5167H17.6209C27.3959 21.5167 35.3292 29.45 35.3292 39.225V47.2292C35.2584 48.0084 34.6917 48.6459 33.9126 48.6459ZM18.1167 45.8834H32.4959V39.225C32.4959 31.15 26.1209 24.6334 18.1167 24.35V45.8834Z" fill="#216FED" />
              <path d="M47.2291 48.6459H30.0874C29.3083 48.6459 28.6708 48.0084 28.6708 47.2292C28.6708 46.45 29.3083 45.8125 30.0874 45.8125H45.8833V33.0625C45.8833 24.9875 39.5083 18.4709 31.5041 18.1875V28.2459C31.5041 29.025 30.8666 29.6625 30.0874 29.6625C29.3083 29.6625 28.6708 29.025 28.6708 28.2459V16.7709C28.6708 15.9917 29.3083 15.3542 30.0874 15.3542H30.9374C40.7124 15.3542 48.6458 23.2875 48.6458 33.0625V47.3C48.6458 48.0084 48.0083 48.6459 47.2291 48.6459Z" fill="#216FED" />
              <path d="M28.246 48.6458H22.296C21.5169 48.6458 20.8794 48.0083 20.8794 47.2292V37.95C20.8794 37.1709 21.5169 36.5334 22.296 36.5334H28.246C29.0252 36.5334 29.6627 37.1709 29.6627 37.95V47.2292C29.6627 48.0083 29.0252 48.6458 28.246 48.6458ZM23.7127 45.8833H26.8294V39.3667H23.7127V45.8833Z" fill="#216FED" />
            </svg> RIPE</h2>
          </Link>
          <div className="login-description">
            <h2 className="main-title mb-2">Welcome To RIPE</h2>
            <p className="">At Ripe, we understand that buying or selling a property is a significant milestone in your life. That's why we are dedicated to providing you with exceptional real estate services and ensuring a smooth and successful transaction every step of the way</p>
            <ul className="social-icons mt-4">
              <li><Link to={"#"}><i className="fab fa-facebook-f"></i></Link></li>
              <li><Link to={"#"}><i className="fab fa-twitter"></i></Link></li>
              <li><Link to={"#"}><i className="fab fa-linkedin-in"></i></Link></li>
            </ul>
            <div className="mt-3 bottom-privacy">
              <p>Copyright © Designed & Developed by <a href="https://paperbirdtech.com/" rel="noreferrer" target="_blank">Paperbird Tech</a> {d.getFullYear()}</p>
            </div>
          </div>
        </div>
        <div className="login-aside-right">
          <div className="row m-0 justify-content-center h-100 align-items-center">
            <div className="p-5">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form-1">
                      <div className="mb-4">
                        <h3 className="dz-title mb-1">Sign in</h3>
                        <p className="">Sign in by entering information below</p>
                      </div>
                      {!props.firstStep && (
                        <form onSubmit={sendOtp}>
                          <div className="form-group">
                            <label className="mb-2 ">
                              <strong>Email</strong>
                            </label>
                            <input type="email" className="form-control"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Type Your Email Address"
                            />
                            {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                          </div>

                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block"
                              disabled={props.showLoading}
                            >
                              Sign In
                            </button>
                          </div>
                        </form>
                      )}
                      {props.firstStep && (
                        <form onSubmit={checkLogin}>
                          <div className="form-group">
                            <label className="mb-2 ">
                              <strong>OTP</strong>
                            </label>
                            <input type="hidden" className="form-control" value={email} />
                            <input type="text" className="form-control"
                              maxLength={4}
                              minLength={4}
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                              placeholder="Enter OTP"
                            />
                            {errors.otp && <div className="text-danger fs-12">{errors.otp}</div>}
                          </div>
                          <div className="text-center cursor-pointer">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block"
                              disabled={props.showLoading}
                            >
                              Sign In
                            </button>
                          </div>
                        </form>
                      )}

                      {/* <div className="new-account mt-2">
                        <p className="">
                          Don't have an account?{" "}
                          <a className="text-primary cursor-pointer" onClick={handleClick}>
                            Sign up
                          </a>
                        </p>
                      </div> */}

                      <ToastContainer />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
    firstStep: state.auth.firstStep,
    secondStep: state.auth.secondStep,
  };
};
export default connect(mapStateToProps)(Login);
