import {
    getUsersData,
    fetchSingleUser,
    updateUser,
} from '../../services/UserService';

import {
    formatError,
    showToast
} from '../../services/AuthService';

export function getUsersDataAction(navigate) {
    return (dispatch) => {
        getUsersData()
            .then((response) => {
                dispatch(fetchUsersSuccess(response.data.result));
            })
            .catch((error) => {
                dispatch(fetchUsersFailure(error.response.data));
            });
    };
}

export function updateUserAction(params, navigate) {
    return (dispatch) => {
        updateUser(params)
            .then((response) => {
                const successMessage = showToast(response.data);
                dispatch(signupSuccessdAction(response.data));
                navigate('/users');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export const fetchUsersSuccess = options => {
    return {
        type: 'FETCH_USERS_SUCCESS',
        payload: options
    };
};

export const fetchUsersFailure = error => {
    return {
        type: 'FETCH_USERS_FAILURE',
        payload: error
    };
};

export function loadingToggleAction(status) {
    return {
        type: 'USERS_LOADING_TOGGLE_ACTION',
        payload: status,
    };
}

export function signupSuccessdAction(payload) {
    return {
        type: 'SIGNUP_CONFIRMED_ACTION',
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: 'SIGNUP_FAILED_ACTION',
        payload: message,
    };
}

export function fetchSingleUserAction(id) {
    return (dispatch) => {
        fetchSingleUser(id)
            .then((response) => {
                dispatch(fetchSingleUserSuccess(response.data.result));
            })
            .catch((error) => {
                dispatch(fetchSingleUserFailure(error.response));
            });
    };
}

export const fetchSingleUserSuccess = options => {
    return {
        type: 'FETCH_SINGLE_USER_SUCCESS',
        payload: options
    };
};

export const fetchSingleUserFailure = error => {
    return {
        type: 'FETCH_SINGLE_USER_FAILURE',
        payload: error
    };
};

