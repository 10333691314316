import axios from 'axios';
import swal from "sweetalert";
import { API_URL } from '../apiConfig';
import { toast } from "react-toastify";
import {
    loginConfirmedAction,
    logout,
    register,
} from '../store/actions/AuthActions';

export function signUp(name, email, password) {
    //axios call
    const postData = {
        name,
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `${API_URL}/auth/signup`,
        postData,
    );
}

export function sendOTP(email) {
    const postData = {
        email,
        returnSecureToken: true,
    };
    return axios.post(
        `${API_URL}/auth/sendotp`,
        postData,
    );
}

export function login(email, otp) {
    const postData = {
        email,
        otp,
        returnSecureToken: true,
    };
    return axios.post(
        `${API_URL}/auth/verifyotp`,
        postData,
    );
}

export function updateProfile(name, address, city, state, county, zip) {

    const postData = {
        name, address, city, state, county, zip
    };

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return axios.post(
        `${API_URL}/user/updateUserProfile`,
        postData,
        requestOptions,
    );
}

export function updateAgentProfile(first_name, middle_name, last_name, office, direct_phone, cell_phone, office_phone, dre, nred) {

    const postData = {
        first_name, middle_name, last_name, office, direct_phone, cell_phone, office_phone, dre, nred
    };

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return axios.post(
        `${API_URL}/user/updateUserProfile`,
        postData,
        requestOptions,
    );
}

export function showToast(toastMessage) {
    if (toastMessage.code === 200 && toastMessage.status === true) {
        toast.success(toastMessage.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
        });
    } else {
        toast.error(toastMessage.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
        });
    }
}

export function formatError(errorResponse) {
    swal("Oops", errorResponse.message, "error");

    // switch (errorResponse.message) {
    //     case 'EMAIL_EXISTS':
    //         //return 'Email already exists';
    //         swal("Oops", "Email already exists", "error");
    //         break;
    //     case 'EMAIL_NOT_FOUND':
    //         //return 'Email not found';
    //         swal("Oops", "Email not found", "error", { button: "Try Again!", });
    //         break;
    //     case 'EMAIL_NOT_VALID':
    //         //return 'Email not found';
    //         swal("Oops", "Email not valid", "error", { button: "Try Again!", });
    //         break;
    //     case 'OTP_NOT_VALID':
    //         //return 'Email not found';
    //         swal("Oops", "OTP not valid", "error", { button: "Try Again!", });
    //         break;
    //     case 'Please enter a valid email address':
    //         //return 'Email not found';
    //         swal("Oops", "Email not valid", "error", { button: "Try Again!", });
    //         break;
    //     case 'INVALID_PASSWORD':
    //         //return 'Invalid Password';
    //         swal("Oops", "Invalid Password", "error", { button: "Try Again!", });
    //         break;
    //     case 'USER_DISABLED':
    //         return 'User Disabled';
    //     default:
    //         return '';
    // }
}

export function saveTokenInLocalStorage(tokenDetails) {
    // For 1 hour
    tokenDetails.expireDate = new Date(
        new Date().getTime() + 72000 * 1000,
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function updateTokenInLocalStorage(tokenDetails) {

    const userDetails = localStorage.getItem('userDetails');
    const userDetailsObject = JSON.parse(userDetails);
    userDetailsObject.name = tokenDetails.name;
    userDetailsObject.first_name = tokenDetails.first_name;
    userDetailsObject.middle_name = tokenDetails.middle_name;
    userDetailsObject.last_name = tokenDetails.last_name;
    userDetailsObject.address = tokenDetails.address;
    userDetailsObject.city = tokenDetails.city;
    userDetailsObject.state = tokenDetails.state;
    userDetailsObject.county = tokenDetails.county;
    userDetailsObject.zip = tokenDetails.zip;
    userDetailsObject.dre = tokenDetails.dre;
    userDetailsObject.nred = tokenDetails.nred;
    userDetailsObject.office = tokenDetails.office;
    userDetailsObject.office_phone = tokenDetails.office_phone;
    userDetailsObject.direct_phone = tokenDetails.direct_phone;
    userDetailsObject.cellPhone = tokenDetails.cellPhone;

    const updatedJsonString = JSON.stringify(userDetailsObject);
    localStorage.setItem('userDetails', updatedJsonString);

}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        dispatch(logout(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(logout(navigate));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(logout(navigate));
        return;
    }
    dispatch(loginConfirmedAction(tokenDetails));

    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, navigate);
}

export function authHeader() {
    let user = JSON.parse(localStorage.getItem('userDetails'))

    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token }
    } else {
        return {}
    }
}

export function checkLogout() {

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return axios.get(
        `${API_URL}/auth/logout`,
        requestOptions,
    );
}
