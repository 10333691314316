import axios from 'axios';
import { API_URL } from '../apiConfig';

export function authHeader() {
    let user = JSON.parse(localStorage.getItem('userDetails'))

    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token }
    } else {
        return {}
    }
}

export function trestleConfig(clientId, clientSecret) {
    const postData = {
        clientId,
        clientSecret
    };

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return axios.post(
        `${API_URL}/trestle/config_check`,
        postData,
        requestOptions,
    );
}

export function sparkConfig(accessToken) {
    const postData = {
        accessToken
    };

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return axios.post(
        `${API_URL}/spark/config_check`,
        postData,
        requestOptions,
    );
}

export function mlsgridConfig(accessToken, refreshToken) {
    const postData = {
        accessToken,
        refreshToken
    };

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return axios.post(
        `${API_URL}/mlsgrid/config_check`,
        postData,
        requestOptions,
    );
}