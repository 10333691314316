import { showToast } from '../../services/AuthService';
import { fetchProperty, fetchSingleProperty, fetchImportProperty, fetchChartProperty } from '../../services/TrestleService';

export function fetchPropertyAction(page, limit, sort, filterValues) {
    return (dispatch) => {
        fetchProperty(page, limit, sort, filterValues)
            .then((response) => {
                dispatch(fetchPropertySuccess(response.data.result));
            })
            .catch((error) => {
                const errorMessage = showToast(error.response.data);
                dispatch(fetchPropertyFailure(error.response));
            });
    };
}

export function fetchTrestlePropertyAction(page, limit, sort, filterValues) {
    return (dispatch) => {
        fetchProperty(page, limit, sort, filterValues)
            .then((response) => {
                dispatch(fetchTrestlePropertySuccess(response.data.result));
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                const errorMessage = showToast(error.response.data);
                dispatch(fetchTrestlePropertyFailure(error.response.data));
            });
    };
}

export const fetchPropertySuccess = options => {
    return {
        type: 'FETCH_PROPERTIES_SUCCESS',
        payload: options
    };
};

export const fetchPropertyFailure = error => {
    return {
        type: 'FETCH_PROPERTIES_FAILURE',
        payload: error
    };
};

export const fetchTrestlePropertySuccess = options => {
    return {
        type: 'FETCH_TRESTLE_PROPERTIES_SUCCESS',
        payload: options
    };
};

export const fetchTrestlePropertyFailure = error => {
    return {
        type: 'FETCH_TRESTLE_PROPERTIES_FAILURE',
        payload: error
    };
};

export function loadingToggleAction(status) {
    return {
        type: 'TRESTLE_LOADING_TOGGLE_ACTION',
        payload: status,
    };
}

export function fetchSinglePropertyAction(propertyType, id) {
    return (dispatch) => {
        fetchSingleProperty(propertyType, id)
            .then((response) => {
                dispatch(fetchSinglePropertySuccess(response.data.result));
            })
            .catch((error) => {
                dispatch(fetchSinglePropertyFailure(error.response));
            });
    };
}

export const fetchSinglePropertySuccess = options => {
    return {
        type: 'FETCH_SINGLE_PROPERTY_SUCCESS',
        payload: options
    };
};

export const fetchSinglePropertyFailure = error => {
    return {
        type: 'FETCH_SINGLE_PROPERTY_FAILURE',
        payload: error
    };
};

export function fetchTrestleImportDataAction(page, limit, filterValues) {
    return (dispatch) => {
        fetchImportProperty(page, limit, filterValues)
            .then((response) => {
                dispatch(fetchTrestleImportSuccess(response.data.result));
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                dispatch(fetchTrestleImportFailure(error.response));
            });
    };
}

export const fetchTrestleImportSuccess = options => {
    return {
        type: 'FETCH_TRESTLE_IMPORT_SUCCESS',
        payload: options
    };
};

export const fetchTrestleImportFailure = error => {
    return {
        type: 'FETCH_TRESTLE_IMPORT_FAILURE',
        payload: error
    };
};

export function fetchTrestleChartDataAction(filterValues) {
    return (dispatch) => {
        fetchChartProperty(filterValues)
            .then((response) => {
                dispatch(fetchTrestleChartSuccess(response.data.result));
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                dispatch(fetchTrestleChartFailure(error.response));
            });
    };
}

export const fetchTrestleChartSuccess = options => {
    return {
        type: 'FETCH_TRESTLE_CHART_SUCCESS',
        payload: options
    };
};

export const fetchTrestleChartFailure = error => {
    return {
        type: 'FETCH_TRESTLE_CHART_FAILURE',
        payload: error
    };
};
