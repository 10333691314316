import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import reportWebVitals from "./reportWebVitals";
//import SimpleReactLightbox from "simple-react-lightbox";
import ThemeContext from "./context/ThemeContext";

const rootElement = document.getElementById('root');
createRoot(rootElement).render(
    <React.StrictMode>
        <Provider store={store}>
            {/* <BrowserRouter basename='react/demo'> */}
            <BrowserRouter basename=''>
                <ThemeContext>
                    <App />
                </ThemeContext>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);
reportWebVitals();
