import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";

import style from './PropertyListing.css';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
	fetchPropertyAction,
	loadingToggleAction,
} from '../../../store/actions/PropertyActions';

export const PropertyListing = ({ propertyData, showLoading }) => {

	const dispatch = useDispatch();
	let user = JSON.parse(localStorage.getItem('userDetails'))
	let sortByValue = user.sortBy ? user.sortBy : 'ModificationTimestamp';

	const [items, setItems] = useState([]);
	const [page, setPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [limit, setLimit] = useState(20);
	const [hasMore, setHasMore] = useState(true);
	const [filterValues, setFilterValues] = useState({
		PropertyType: user.filters && user.filters.PropertyType,
		MlsStatus: user.filters && user.filters.MlsStatus,
		OperatingSystem: user.filters && user.filters.OperatingSystem,
		MinListPrice: user.filters && user.filters.MinListPrice,
		MaxListPrice: user.filters && user.filters.MaxListPrice,
		ModificationTimestamp: user.filters && user.filters.ModificationTimestamp,
	});
	const [sort, setSort] = useState(sortByValue);
	const [totalPages, setTotalPages] = useState(0);
	const [radioSelectOption, setRadioSelectOption] = useState('trestle');


	const handleSortChange = event => {
		setSort(event.target.value);
	};

	const handleRadioChange = (event) => {
		setRadioSelectOption(event.target.value);
	};

	const handleSubmit = (event) => {

		filterValues.PropertyType = radioSelectOption;

		user.sortBy = sort;
		localStorage.setItem('userDetails', JSON.stringify(user));
		event.preventDefault();

		setItems([]);
		setPage(currentPage);

		fetchNewProperty();
	}

	const fetchNewProperty = () => {

		dispatch(loadingToggleAction(true))
		dispatch(fetchPropertyAction(currentPage, limit, sort, filterValues));
	}

	const fetchProperty = () => {

		dispatch(loadingToggleAction(true))
		dispatch(fetchPropertyAction(page, limit, sort, filterValues));
	}

	useEffect(() => {
		setItems([]);

		var condition = true;

		if (user && user.filters && user.filters.PropertyType) {
			var radioButton = document.getElementById(user.filters.PropertyType);
			radioButton.defaultChecked = condition;

			fetchProperty();

		} else {
			var radioButton = document.getElementById('trestle');
			radioButton.defaultChecked = condition;
		}


	}, []);

	useEffect(() => {

		if (propertyData.docs) {
			if (propertyData.docs.length === 0) {
				setHasMore(false);
			} else {

				setItems([...items, ...propertyData.docs]);
				setPage(page + 1);

				setHasMore(propertyData.hasNextPage);
				setTotalPages(propertyData.totalPages);
			}
		}

	}, [propertyData.docs]);

	return (
		<>
			<PageTitle
				activeMenu="Property Listing"
				motherMenu="Form"
				pageContent="Property Listing"
			/>
			<div className="card">
				<div className="card-header">
					<h4 className="card-title">Property Listing</h4>
					<div className="custom-dropdown-temp custom-dropdown mb-0 dropdown">
						<div className="row">
							<div className='col-md-12 filter-menu'>
								<label className="radio-inline me-3">
									<input
										type="radio"
										id="trestle"
										name="propertyType"
										value="trestle"
										onChange={handleRadioChange}
									/> Trestle
								</label>

								<label className="radio-inline me-3">
									<input
										type="radio"
										id="spark"
										name="propertyType"
										value="spark"
										onChange={handleRadioChange}
									/> Spark
								</label>

								<select
									className='form-control form-control-sm'
									name="sort"
									onChange={handleSortChange}
									defaultValue={sortByValue}
								>
									<option
										value="ModificationTimestamp"
									>ModificationTimestamp</option>
									<option
										value="ListPrice"
									>ListPrice</option>
								</select>

								<button
									type="button"
									onClick={handleSubmit}
									className="me-2 btn btn-primary btn-sm btn-rounded"
									disabled={showLoading}
								>
									<span className="btn-icon-start text-info">
										<i className="fa fa-play color-info"></i>
									</span>Search
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="card-body">
					{!showLoading && items.length === 0 && <h4>No records found.</h4>}
					{/* {showLoading && <h4>Loading...</h4>} */}
					<InfiniteScroll
						dataLength={items.length}
						next={fetchProperty}
						hasMore={page <= totalPages}
						style={{ overflow: 'unset' }}
					>
						{items.map((item, index) => (
							<div className="row" key={index}>
								<div className="col-lg-12 col-xl-12 col-xxl-12">
									<div className="card">
										<div className="card-body p-4">
											<div className="row m-b-30">
												<div className="col-md-12 col-xxl-12">
													<div className="new-arrival-content position-relative">
														<h4>
															<Link to={`/property-details/${item.ListingType.toLowerCase()}/${item._id}`}>
																{item.ListingType && (item.ListingType.toUpperCase())}
															</Link>
														</h4>
														<div className="comment-review star-rating">
															<span className="review-text">
																{item.OriginatingSystemName && (item.OriginatingSystemName.toUpperCase())}
															</span>
															<p className="price">
																${item.Data && item.Data[0].ListPrice && (item.Data[0].ListPrice)}
															</p>
														</div>
													</div>
												</div>
												<div className="col-md-5 col-xxl-3">
													<div className="new-arrival-product  mb-md-0">
														<div className="new-arrivals-img-contnent">
															{item.Data && item.Data[0].Media && item.Data[0].Media[0].MediaURL && (<img className="img-fluid-temp img-fluid" src={item.Data[0].Media[0].MediaURL} alt="" />)}
														</div>
													</div>
												</div>
												<div className="col-md-7 col-xxl-9">
													<table align="center" width="100%" className="table table-bordered">
														<tbody>
															<tr>
																<td align="center" colSpan={3}>
																	Listing Id : &nbsp;
																	<span className="item">
																		<b>
																			{item.ListingId && (item.ListingId)}
																		</b>
																	</span>
																</td>
															</tr>
															<tr>
																<td align="center">
																	<p>Property Type: </p>
																	<p className="item"><b>
																		{item.Data && item.Data[0].PropertyType && (item.Data[0].PropertyType)}
																	</b></p>
																</td>
																<td align="center">
																	<p>Property Sub Type: </p>
																	<p className="item"><b>
																		{item.Data && item.Data[0].PropertySubType && (item.Data[0].PropertySubType)}
																	</b></p>
																</td>
																<td align="center">
																	<p>Universal ID: </p>
																	<p className="item"><b>
																		{item.Data && item.Data[0].UniversalPropertyId ? (item.Data[0].UniversalPropertyId) : (
																			<span>N/A</span>
																		)}
																	</b></p>
																</td>
															</tr>
															<tr>
																<td align="center">
																	<p>Standard Status: </p>
																	<p className="item"><b>
																		{item.Data && item.Data[0].StandardStatus && (item.Data[0].StandardStatus)}
																	</b></p>
																</td>
																<td align="center">
																	<p>Parking Feature:</p>
																	<p className="item">
																		<b>
																			{item.Data && item.Data[0].ParkingFeatures ? (item.Data[0].ParkingFeatures) : (
																				<span>N/A</span>
																			)}
																		</b>
																	</p>
																</td>
																<td align="center">
																	<p>Modification Time: </p><p className="item">
																		<b>
																			{item.ModificationTimestamp && (<span>{new Date(item.ModificationTimestamp).toLocaleString()}</span>)}
																		</b>
																	</p>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
												<div className="row m-b-30 mt-2">
													<div className="col-lg-12 col-xl-12 col-xxl-12">
														<div className="new-arrival-content position-relative">
															<p className="text-content">
																{item.Data && item.Data[0].PublicRemarks && (item.Data[0].PublicRemarks)}
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
					</InfiniteScroll >
					{showLoading && <h4 className='text-center'>Loading...</h4>}
				</div >
			</div >
		</>
	)

}

const mapStateToProps = (state) => {
	// console.log('state', state)
	return {
		errorMessage: state.property.errorMessage,
		successMessage: state.property.successMessage,
		showLoading: state.property.showLoading,
		propertyData: state.property.propertyData,
	};
};
export default connect(mapStateToProps)(PropertyListing);