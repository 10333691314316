import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import style from './Spark.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Row, Card, Col, Alert, Button } from "react-bootstrap";

import {
	fetchSparkPropertyAction,
	loadingToggleAction,
} from '../../../store/actions/SparkActions';

import {
	getGroupByDataAction,
	loadingToggleAction as groupbyLoadingToggleAction,
} from '../../../store/actions/DownloadActions';

import Select from "react-select";

const CustomClearText = () => "clear all";
const ClearIndicator = (props) => {

	const {
		children = <CustomClearText />,
		getStyles,
		innerProps: { ref, ...restInnerProps },
	} = props;

	return (
		<div
			{...restInnerProps}
			ref={ref}
			style={getStyles("clearIndicator", props)}
		>
			<div style={{ padding: "0px 5px" }}>{children}</div>
		</div>
	);
};

const ClearIndicatorStyles = (base, state) => ({
	...base,
	cursor: "pointer",
	color: state.isFocused ? "blue" : "black",
});

export const Spark = ({ osOptions, statusOptions, propertyData, showLoading, showGroupByLoading }) => {

	const dispatch = useDispatch();
	const sortOptionsList = [
		{ value: 'ModificationTimestamp', label: 'ModificationTimestamp' },
		{ value: 'ListPrice', label: 'ListPrice' },
	];

	const isFirstRender = useRef(true);
	const defaultSortValue = sortOptionsList[0];

	const [items, setItems] = useState([]);
	const [page, setPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(null);
	const [limit, setLimit] = useState(10);
	const [hasMore, setHasMore] = useState(true);
	const [filterValues, setFilterValues] = useState({
		PropertyType: 'spark',
		MlsStatus: statusOptions,
		OriginatingSystemName: osOptions,
	});
	const [sort, setSort] = useState(defaultSortValue);
	const propertyType = 'spark';

	let user = JSON.parse(localStorage.getItem('userDetails'))
	let sparkConfig = user.sparkConfig;

	const [totalPages, setTotalPages] = useState(0);
	const [totalRecords, setTotalRecords] = useState(0);
	const [fromRecords, setFromRecords] = useState(0);
	const [toRecords, setToRecords] = useState(0);
	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const [osSelectedOption, setOsSelectedOption] = useState(null);
	const [osOptionsList, setOSOptionsList] = useState(osOptions);

	const [statusSelectedOptions, setStatusSelectedOptions] = useState(null);
	const [statusOptionsList, setStatusOptionsList] = useState(statusOptions);

	const handleSubmit = (event) => {

		event.preventDefault();

		let OriginatingSystemArray = [];

		if (osSelectedOption && osSelectedOption.length > 0) {
			OriginatingSystemArray = Array.from(osSelectedOption)
				.map(option => option.value)
		} else {
			OriginatingSystemArray = Array.from(osOptionsList)
				.map(option => option.value);
		}

		let MlsStatusArray = [];

		if (statusSelectedOptions && statusSelectedOptions.length > 0) {
			MlsStatusArray = Array.from(statusSelectedOptions)
				.map(option => option.value)
		} else {
			MlsStatusArray = Array.from(statusOptionsList)
				.map(option => option.value);
		}

		filterValues.MlsStatus = MlsStatusArray;
		filterValues.OriginatingSystemName = OriginatingSystemArray;

		setItems([]);
		setPage(1);
		setCurrentPage(1);

		fetchNewProperty();
	}

	const fetchNewProperty = () => {
		// console.log('filterValues', filterValues)
		if (currentPage !== null) {
			isFirstRender.current = false;

			dispatch(loadingToggleAction(true))
			dispatch(fetchSparkPropertyAction(currentPage, limit, sort, filterValues));
		}
	}

	// const fetchProperty = () => {

	// 	dispatch(loadingToggleAction(true))
	// 	dispatch(fetchSparkPropertyAction(page, limit, sort));
	// }

	useEffect(() => {
		if (sparkConfig) {
			dispatch(groupbyLoadingToggleAction(true));
			dispatch(getGroupByDataAction('OriginatingSystemName', propertyType));
			dispatch(getGroupByDataAction('MlsStatus', propertyType));
		}
		setItems([]);

		// fetchProperty();

	}, []);

	useEffect(() => {

		let newOsOptions = []
		let newStatusOptions = []

		osOptions.map((val, index) => {
			newOsOptions.push({ value: val, label: val })
		})
		setOSOptionsList(newOsOptions)

		statusOptions.map((val, index) => {
			newStatusOptions.push({ value: val, label: val })
		})
		setStatusOptionsList(newStatusOptions)
	}, [osOptions, statusOptions]);

	useEffect(() => {
		dispatch(loadingToggleAction(false))
		if (currentPage !== null && currentPage) {
			if (osOptions.length > 0 && statusOptions.length > 0) {
				fetchNewProperty();
			}
		}
	}, [currentPage]);

	useEffect(() => {

		if (propertyData.docs) {
			if (propertyData.docs.length === 0) {
				setHasMore(false);
				setItems([]);
			} else {

				// setItems([...items, ...propertyData.docs]);
				setItems([...propertyData.docs]);
				setPage(page + 1);

				setHasMore(propertyData.hasNextPage);
				setTotalPages(propertyData.totalPages);
				setTotalRecords(propertyData.totalDocs);
				setFromRecords(propertyData.pagingCounter);
				setToRecords(propertyData.pagingCounter + limit - 1);
			}
		}

	}, [propertyData.docs]);

	const renderPaginationButtons = () => {
		const buttonsToShow = 5; // Number of pagination buttons to show
		const halfButtons = Math.floor(buttonsToShow / 2);
		const pages = [];

		for (let i = Math.max(1, currentPage - halfButtons); i <= Math.min(totalPages, currentPage + halfButtons); i++) {
			pages.push(i);
		}

		return (
			<div className="d-flex align-items-center justify-content-between  flex-wrap">
				{totalRecords > toRecords ? (
					<h5>Showing {fromRecords} to {toRecords} of {totalRecords} entries</h5>
				) : (
					<h5>Showing {fromRecords} to {totalRecords} of {totalRecords} entries</h5>
				)}
				<ul className="pagination align-items-center">
					{currentPage > 1 && (
						<>
							<li className="page-item page-indicator">
								<button className="btn btn-primary btn-sm me-2" onClick={() => handlePageChange(1)}>First</button>
							</li>
							<li className="page-item page-indicator">
								<button className="btn btn-primary btn-sm me-2" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
							</li>
						</>
					)}

					{pages.map((pageNumber, index) => (
						<li className="page-item" key={index}>
							<button
								className={`btn btn-sm me-2 ${currentPage === pageNumber ? 'btn-danger' : 'btn-primary'}`}
								key={pageNumber}
								onClick={() => handlePageChange(pageNumber)}
								disabled={currentPage === pageNumber}
							>
								{pageNumber}
							</button>
						</li>
					))}

					{currentPage < totalPages && (
						<>
							<li className="page-item page-indicator">
								<button className="btn btn-primary btn-sm me-2" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
							</li>

							<li className="page-item page-indicator">
								<button className="btn btn-primary btn-sm me-2" onClick={() => handlePageChange(totalPages)}>Last</button>
							</li>
						</>
					)}
				</ul>
			</div>
		);
	};

	return (
		<>
			<PageTitle
				activeMenu="Spark Data"
				motherMenu="Home"
				pageContent="Spark Data"
			/>

			<ToastContainer />

			<div className="row">
				<div className="col-xl-12">
					<div className="card mx-0">
						<div className="card-body">
							<div className="row">
								<div className="col-md-12 order-md-1">
									<h4 className="mb-3">Filter Spark Data</h4>
									{!sparkConfig ? (
										<Alert variant="danger" className="solid alert-square">
											<strong>Error! </strong> Please confirm Spark Configuration first ..
										</Alert>

									) : (
										<div className="row">
											<div className="mb-3 row">
												<label className="col-sm-2 col-form-label col-form-label-sm">Operating System</label>
												<div className="col-sm-7">
													<Select
														closeMenuOnSelect={false}
														components={{ ClearIndicator }}
														styles={{ clearIndicator: ClearIndicatorStyles }}
														value={osSelectedOption}
														name="OriginatingSystemName1"
														isMulti
														options={osOptionsList}
														onChange={(selectedOption) => {
															setOsSelectedOption(selectedOption);
														}
														}
													/>
												</div>
											</div>

											<div className="mb-3 row">
												<label className="col-sm-2 col-form-label col-form-label-sm">MLS Status</label>
												<div className="col-sm-7">
													<Select
														closeMenuOnSelect={false}
														components={{ ClearIndicator }}
														styles={{ clearIndicator: ClearIndicatorStyles }}
														value={statusSelectedOptions}
														name="MlsStatus"
														isMulti
														options={statusOptionsList}
														onChange={(selectedOption) => {
															setStatusSelectedOptions(selectedOption);
														}
														}
													/>
												</div>
											</div>

											<div className="mb-3 row">
												<label className="col-sm-2 col-form-label col-form-label-sm">Sort By</label>
												<div className="col-sm-7">
													<Select
														closeMenuOnSelect={true}
														isMulti={false}
														value={sort}
														name="sort"
														options={sortOptionsList}
														defaultValue={sortOptionsList[0]}
														onChange={(selectedOption) => {
															setSort(selectedOption)
														}
														}
													/>
												</div>
											</div>

											<hr className="mb-4" />
											<div className="mb-3 row">
												<div className="col-sm-7">
													<button
														type="button"
														onClick={handleSubmit}
														className="me-2 btn btn-primary btn-sm btn-rounded"
														disabled={showLoading}
													>
														<span className="btn-icon-start text-info">
															<i className="fa fa-play color-info"></i>
														</span>Search
													</button>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div >

			<div className="row">

				{!showLoading && !isFirstRender.current && items.length === 0 && <h4 className='text-center'>No records found.</h4>}
				{/* <InfiniteScroll
					dataLength={items.length}
					next={fetchProperty}
					hasMore={page <= totalPages}
					style={{ overflow: 'unset' }} */}
				{/* > */}
				{items.map((item, index) => (
					<div className="col-xl-12" key={index}>
						<div className="card mx-0">
							<div className="card-body p-4">
								<div className="row m-b-30">
									<div className="col-md-12 col-xxl-12">
										<div className="new-arrival-content position-relative">
											<h4>
												<Link to={`/${item.ListingType.toLowerCase()}-details/${item._id}`}>
													{item.ListingType && (item.ListingType.toUpperCase())}
												</Link>
											</h4>
											<div className="comment-review star-rating">
												<span className="review-text">
													{item.OriginatingSystemName && (item.OriginatingSystemName.toUpperCase())}
												</span>
												<p className="price">
													${item.Data && item.Data[0].ListPrice && (item.Data[0].ListPrice)}
												</p>
											</div>
										</div>
									</div>
									<div className="col-md-5 col-xxl-3">
										<div className="new-arrival-product  mb-md-0">
											<div className="new-arrivals-img-contnent">
												{item.Data && item.Data[0].Media && item.Data[0].Media[0].MediaURL && (<img className="img-fluid-temp img-fluid" src={item.Data[0].Media[0].MediaURL} alt="" />)}
											</div>
										</div>
									</div>
									<div className="col-md-7 col-xxl-9">
										<table align="center" width="100%" className="table table-bordered">
											<tbody>
												<tr>
													<td align="center" colSpan={3}>
														Listing Id : &nbsp;
														<span className="item">
															<b>
																{item.ListingId && (item.ListingId)}
															</b>
														</span>
													</td>
												</tr>
												<tr>
													<td align="center">
														<p>Property Type: </p>
														<p className="item"><b>
															{item.Data && item.Data[0].PropertyType && (item.Data[0].PropertyType)}
														</b></p>
													</td>
													<td align="center">
														<p>Property Sub Type: </p>
														<p className="item"><b>
															{item.Data && item.Data[0].PropertySubType && (item.Data[0].PropertySubType)}
														</b></p>
													</td>
													<td align="center">
														<p>Universal ID: </p>
														<p className="item"><b>
															{item.Data && item.Data[0].UniversalPropertyId ? (item.Data[0].UniversalPropertyId) : (
																<span>N/A</span>
															)}
														</b></p>
													</td>
												</tr>
												<tr>
													<td align="center">
														<p>Standard Status: </p>
														<p className="item"><b>
															{item.Data && item.Data[0].StandardStatus && (item.Data[0].StandardStatus)}
														</b></p>
													</td>
													<td align="center">
														<p>Parking Feature:</p>
														<p className="item">
															<b>
																{item.Data && item.Data[0].ParkingFeatures ? (item.Data[0].ParkingFeatures) : (
																	<span>N/A</span>
																)}
															</b>
														</p>
													</td>
													<td align="center">
														<p>Modification Time: </p><p className="item">
															<b>
																{item.ModificationTimestamp && (<span>{new Date(item.ModificationTimestamp).toLocaleString()}</span>)}
															</b>
														</p>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div className="row m-b-30 mt-2">
										<div className="col-lg-12 col-xl-12 col-xxl-12">
											<div className="new-arrival-content position-relative">
												<p className="text-content">
													{item.Data && item.Data[0].PublicRemarks && (item.Data[0].PublicRemarks)}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
				{/* </InfiniteScroll > */}

			</div >
			{items && items.length > 0 && renderPaginationButtons()}

			{showLoading && (
				<div className='loader-sec'>
					<div className="loader">Loading...</div>
				</div>
			)}

			{showGroupByLoading && (
				<div className='loader-sec'>
					<div className="loader">Loading...</div>
				</div>
			)}

		</>
	)

}

const mapStateToProps = (state) => {
	// console.log('spark state', state.spark.showLoading)
	return {
		errorMessage: state.spark.errorMessage,
		successMessage: state.spark.successMessage,
		showLoading: state.spark.showLoading,
		showGroupByLoading: state.groupby.showLoading,
		propertyData: state.spark.propertySparkData,
		osOptions: state.groupby.osOptions,
		statusOptions: state.groupby.statusOptions,
	};
};
export default connect(mapStateToProps)(Spark);