
const initialState = {
    userData: [],
    singleUserData: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function UserReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_USERS_SUCCESS':
            return {
                ...state,
                showLoading: false,
                userData: action.payload,
                errorMessage: '',
                successMessage: '',
            };
        case 'FETCH_USERS_FAILURE':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        case 'USERS_LOADING_TOGGLE_ACTION':
            return {
                ...state,
                showLoading: action.payload,
            };
        case 'SIGNUP_CONFIRMED_ACTION':
            return {
                ...state,
                showLoading: false,
                userData: action.payload
            };
        case 'SIGNUP_FAILED_ACTION':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        case 'FETCH_SINGLE_USER_SUCCESS':
            return {
                ...state,
                showLoading: false,
                singleUserData: action.payload
            };
        case 'FETCH_SINGLE_USER_FAILURE':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
}


