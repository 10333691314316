import axios from 'axios';
import { API_URL } from '../apiConfig';

export function authHeader() {
    let user = JSON.parse(localStorage.getItem('userDetails'))

    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token }
    } else {
        return {}
    }
}

export function getGroupByData(groupbyName, propertyType) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return axios.get(
        `${API_URL}/` + propertyType + `/groupby/` + groupbyName,
        requestOptions,
    );
}

export function getAllOsData() {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return axios.get(
        `${API_URL}/user/get/brokers_mls_list`,
        requestOptions,
    );
}

export function downloadData(PropertyType, MlsStatus, MlsType, MinListPrice, MaxListPrice, ModificationTimestamp, ImportStart) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    let postData = {
        PropertyType,
        MlsStatus,
        MlsType,
        ModificationTimestamp,
        ImportStart
    };

    const property_type = PropertyType.toLowerCase();

    if (property_type !== 'mlsgrid') {
        postData = {
            ...postData,
            MinListPrice,
            MaxListPrice
        };
    }

    return axios.post(
        `${API_URL}/` + property_type + `/import`,
        postData,
        requestOptions,
    );
}
