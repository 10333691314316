
const initialState = {
    agentData: [],
    singleAgentData: {
        _id: '',
        name: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        office: '',
        phone: '',
        direct_phone: '',
        cell_phone: '',
        office_phone: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        dre: '',
        nred: '',
        date_of_birth: '',
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function AgentReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_AGENTS_SUCCESS':
            return {
                ...state,
                showLoading: false,
                agentData: action.payload,
                errorMessage: '',
                successMessage: '',
            };
        case 'FETCH_AGENTS_FAILURE':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        case 'AGENTS_LOADING_TOGGLE_ACTION':
            return {
                ...state,
                showLoading: action.payload,
            };
        case 'AGENT_SIGNUP_CONFIRMED_ACTION':
            return {
                ...state,
                showLoading: false,
                errorMessage: '',
                successMessage: action.payload.message,
            };
        case 'AGENT_SIGNUP_FAILED_ACTION':
            return {
                ...state,
                showLoading: false,
                errorMessage: action.payload,
                successMessage: '',
            };
        case 'FETCH_SINGLE_AGENT_SUCCESS':
            return {
                ...state,
                showLoading: false,
                singleAgentData: action.payload
            };
        case 'FETCH_SINGLE_AGENT_FAILURE':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
}


