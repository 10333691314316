
const initialState = {
    propertyData: [],
    propertySparkData: [],
    singlePropertyData: [],
    importSparkData: [],
    chartSparkData: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function SparkReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_PROPERTIES_SUCCESS':
            // if (action.payload && action.payload.docs && action.payload.docs.length > 0) {
            return {
                ...state,
                showLoading: false,
                propertyData: action.payload
            };
        // }
        case 'FETCH_PROPERTIES_FAILURE':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        case 'FETCH_SPARK_PROPERTIES_SUCCESS':
            // if (action.payload && action.payload.docs && action.payload.docs.length > 0) {
            return {
                ...state,
                showLoading: false,
                propertySparkData: action.payload
            };
        // }
        case 'FETCH_SPARK_PROPERTIES_FAILURE':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        case 'FETCH_SINGLE_PROPERTY_SUCCESS':
            return {
                ...state,
                showLoading: false,
                singlePropertyData: action.payload
            };
        case 'FETCH_SINGLE_PROPERTY_FAILURE':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        case 'DOWNLOAD_LOADING_TOGGLE_ACTION':
            return {
                ...state,
                showLoading: action.payload,
            };
        case 'SPARK_LOADING_TOGGLE_ACTION':
            return {
                ...state,
                showLoading: action.payload,
            };
        case 'FETCH_SPARK_IMPORT_SUCCESS':
            if (action.payload && action.payload.docs && action.payload.docs.length > 0) {
                return {
                    ...state,
                    showLoading: false,
                    importSparkData: action.payload
                };
            }
        case 'FETCH_SPARK_IMPORT_FAILURE':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        case 'FETCH_SPARK_CHART_SUCCESS':
            return {
                ...state,
                showLoading: false,
                chartSparkData: action.payload
            };
        case 'FETCH_SPARK_CHART_FAILURE':
            return {
                ...state,
                showLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
}


